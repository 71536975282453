import { useCallback, useEffect, useState } from "react";
import client from "utilities/apiClient";
import queryClient from "utilities/queryClient";

// TODO - Create endpoint enum or union type
type Endpoint = string;

type UseGetWithLoaderConfig = {
  isLazy?: boolean; // Does not fetch upon mount. Instead, use `lazyFetch` to declaratively fetch
  loadOnRefetch?: boolean; // When refetching, it does not change the loading state
  staleTime?: number;
};

/**
 * USE THIS HOOK SPARINGLY
 *
 * FAVOR ROUTE LOADERS INSTEAD
 *
 *
 * Route loaders don't have a mechanism to refetch data.
 * Use this hook for instances where you want to use a route loader,
 * but still want refetch capabilities.
 *
 * Since route loaders can fetch different endpoints, make sure the data passed
 * into this hook matches the same endpoint used to fetch the initial data.
 *
 * Returns the data and loading states for a given endpoint.
 * Also provides options to lazily load the data.
 *
 * @example
 * ```jsx
 * type Data = {
 *   title: string;
 * }
 *
 * const {data, isLoading} = useGetWithLoader<Data>(ENDPOINT, initialData);
 *
 *
 * if (isLoading) {
 *  return <Loader />
 * }
 *
 * return <div>{data.title}</div>
 * ```
 *
 * @param endpoint The endpoint which you need to fetch data
 * @param config Internal configuration for this hook
 * @returns
 */
export default function useGetWithLoader<TData>(
  endpoint: string | Endpoint,
  initialLoaderData: TData,
  config: UseGetWithLoaderConfig = {}
) {
  const { isLazy = true, loadOnRefetch = true } = config;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TData>(initialLoaderData);

  const genData = useCallback(
    async (maybeEndpoint?: string) => {
      return await client<ApiPayload<TData>>(maybeEndpoint ?? endpoint, {
        staleTime: config.staleTime,
      });
    },
    [config.staleTime, endpoint]
  );

  useEffect(() => {
    if (!isLazy) {
      setIsLoading(true);
      genData().then((resp) => {
        if (resp.status === "failure") {
          throw new Error(resp.response);
        }
        setData(resp.data);
        setIsLoading(false);
      });
    }
  }, [genData, isLazy, endpoint, loadOnRefetch]);

  /**
   *
   * @returns Lazily fetches from the endpoint
   */
  const lazyFetch = useCallback(
    async function lazyFetch(maybeEndpoint?: string) {
      queryClient.invalidateQueries(maybeEndpoint ?? endpoint);
      if (loadOnRefetch) {
        setIsLoading(true);
      }
      const resp = await genData(maybeEndpoint);
      setData(resp.data);
      setIsLoading(false);
      return resp.data;
    },
    [endpoint, genData, loadOnRefetch]
  );

  const refetch = useCallback(
    async function refetch() {
      queryClient.invalidateQueries(endpoint);
      return await lazyFetch(endpoint);
    },
    [endpoint, lazyFetch]
  );

  return {
    data,
    isLoading,
    lazyFetch,
    refetch,
  };
}
