import { SvgIcon, SvgIconProps } from "@mui/material";

export default function BellIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.99954 23.5C11.5763 23.5 12.8553 22.213 12.8553 20.625H7.14374C7.14374 22.213 8.42276 23.5 9.99954 23.5ZM19.6152 16.7747C18.7527 15.8422 17.1388 14.4393 17.1388 9.84375C17.1388 6.35332 14.7067 3.55918 11.4272 2.87367V1.9375C11.4272 1.14373 10.7879 0.5 9.99954 0.5C9.21115 0.5 8.57186 1.14373 8.57186 1.9375V2.87367C5.2924 3.55918 2.86026 6.35332 2.86026 9.84375C2.86026 14.4393 1.24642 15.8422 0.383918 16.7747C0.11606 17.0645 -0.00268956 17.4108 -0.000457416 17.75C0.0044533 18.4867 0.579007 19.1875 1.43258 19.1875H18.5665C19.4201 19.1875 19.9951 18.4867 19.9995 17.75C20.0018 17.4108 19.883 17.064 19.6152 16.7747Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
