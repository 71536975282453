/* eslint-disable react/function-component-definition */
import { TableCell as MuiTableCell, TableCellProps } from "@mui/material";

/**
 * An abstraction around the MuiTableCell component setting the component as a 'div'.
 *
 * Why not override via `defaultProps` in the ThemeProvider?
 * It turns out because MUI's Table has overridable props, only some props
 * are exposed to be overridden, and `component` is not one of them for ALL
 * the table elements.
 */
const TableCell: typeof MuiTableCell = (props: TableCellProps): JSX.Element => {
  return <MuiTableCell component="div" {...props} />;
};

export default TableCell;
