import * as React from "react";
import { Card, CardContent, Stack } from "@mui/material";
import { EmailVerificationStatus } from "@presta-technologies-inc/presta-types";
import getEmailVerificationValidateCodeEndpoint from "api/endpoints/getEmailVerificationValidateCodeEndpoint";
import useLoaderData from "hooks/useLoaderData";
import client from "utilities/apiClient";
import EmailVerificationVerifyCodeContent from "./EmailVerificationVerifyCodeContent";

// TODO(@dwidjaja945) - update type to use presta-types after backend pr is merged
export type EmailVerificationVerifyCodePageLoaderData = {
  isVerified: boolean;
  status: string;
};

export async function genEmailVerificationVerifyCodePageLoader(
  code: string,
  didAcceptTermsOfServiceAndPrivacyPolicy = true
) {
  const { data } = await client<
    ApiPayload<{
      isVerified: boolean;
      status: EmailVerificationStatus;
    }>
  >(getEmailVerificationValidateCodeEndpoint(), {
    body: {
      code,
      didAcceptTermsOfServiceAndPrivacyPolicy,
    },
    method: "POST",
  });
  return {
    isVerified: data.isVerified,
    status: data.status,
  };
}

export default function EmailVerificationVerifyCodePage() {
  const { isVerified, status } =
    useLoaderData<EmailVerificationVerifyCodePageLoaderData>();

  return (
    <Stack alignItems="center" flexGrow={1} justifyContent="center" p={3}>
      <Card>
        <CardContent sx={{ maxWidth: 325, textAlign: "center" }}>
          <EmailVerificationVerifyCodeContent
            isVerified={isVerified}
            status={status}
          />
        </CardContent>
      </Card>
    </Stack>
  );
}
