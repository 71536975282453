import { To } from "react-router-dom";
import AppRoutes from "routes/AppRoutes/AppRoutes";

export type NavConfig = Partial<{
  locationProps?: Partial<Location>;
  paramsToPersist?: Array<string>;
  /**
   * This property will override `paramsToPersist`
   */
  persistAllParams?: true;
  searchParams?: { [param: string]: string };
}>;

const PERSIST_PARAMS_SET = new Set(["gk", "gk_enable", "gk_disable"]);
export default function getNavigateConfig(
  pathname: string | AppRoutes,
  config: NavConfig = {}
): To {
  const { locationProps, paramsToPersist, persistAllParams } = config;
  const paramsToPersistSet = new Set(PERSIST_PARAMS_SET);

  for (const param of paramsToPersist ?? []) {
    paramsToPersistSet.add(param);
  }

  let search: URLSearchParams | string = new URLSearchParams(
    locationProps?.search ?? window.location.search
  );

  const newSearch: { [key: string]: string } = {};
  search.forEach((value, key) => {
    if (paramsToPersistSet.has(key) || persistAllParams) {
      newSearch[key] = value;
    }
  });
  Object.entries(config?.searchParams ?? {}).forEach(([param, value]) => {
    if (value === "undefined" || value === "null") {
      return;
    }
    newSearch[param] = value;
  });

  search = new URLSearchParams(newSearch).toString();

  return {
    ...window.location,
    ...(locationProps ?? {}),
    pathname,
    search,
  };
}
