import * as React from "react";
import { useRef, useState } from "react";
import {
  KnockFeedProvider,
  NotificationFeedPopover,
  UnseenBadge,
} from "@knocklabs/react-notification-feed";
import { useUserContextV2 } from "context/UserContextV2";
import BellIcon from "static_assets/svg/BellIcon";
import { usePrestaCookies } from "context/PrestaCookiesContext";
import { Box, SvgIconProps, styled } from "@mui/material";
import stylex from "utilities/stylex";

// Required CSS import, unless you're overriding the styling
import "@knocklabs/react-notification-feed/dist/index.css";

const Styled = styled("div")(({ theme }) => ({
  ".rnf-feed-provider *": {
    fontFamily: "Space Grotesk !important",
  },

  ".rnf-notification-cell__inner": {
    backgroundColor: theme.palette.background.default,
  },
  ".rnf-notification-cell__inner:has(.rnf-notification-cell__unread-dot)": {
    backgroundColor: theme.palette.background.paper,
  },
  ".rnf-notification-cell__inner:has(.rnf-notification-cell__unread-dot):hover":
    {
      backgroundColor: theme.palette.action.hover,
    },
  ".rnf-notification-cell__inner:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  ".rnf-notification-cell__timestamp": {
    color: theme.palette.grey[700],
  },
  ".rnf-notification-cell__unread-dot": {
    backgroundColor: "transparent",
    border: "none",
  },
  ".rnf-unseen-badge": {
    backgroundColor: theme.palette.error.main,
  },
}));

const styles = stylex({
  badge: {
    position: "absolute",
    right: "1.5rem",
    top: 0,
  },
  notifButton: {
    color: "white",
    position: "relative",
  },
});

export default function NotificationIcon({
  color = "inherit",
  sx = {},
}: {
  color?: SvgIconProps["color"];
  sx?: SvgIconProps["sx"];
}) {
  const [isVisible, setIsVisible] = useState(false);
  const { viewerContext } = useUserContextV2();
  const {
    cookies: { knockToken },
  } = usePrestaCookies();
  const notifButtonRef = useRef(null);

  const { code } = viewerContext.getUserX();

  const hasKnockToken = (knockToken?.length ?? 0) > 0;
  const hasCode = (code?.length ?? 0) > 0;

  if (!hasKnockToken || !hasCode) {
    return null;
  }

  return (
    <Styled>
      <KnockFeedProvider
        apiKey={process.env.REACT_APP_KNOCK_PUBLIC_API_KEY ?? ""}
        feedId={process.env.REACT_APP_KNOCK_FEED_ID ?? ""}
        userId={code}
        userToken={knockToken}
      >
        <>
          <Box
            ref={notifButtonRef}
            onClick={() => setIsVisible(!isVisible)}
            onKeyUp={() => setIsVisible(!isVisible)}
            role="button"
            sx={{ ...styles.notifButton, ...sx }}
            tabIndex={-1}
          >
            <BellIcon color={color} />
            <Box sx={styles.badge}>
              <UnseenBadge badgeCountType="unread" />
            </Box>
          </Box>
          <NotificationFeedPopover
            buttonRef={notifButtonRef}
            isVisible={isVisible}
            onClose={() => setIsVisible(false)}
          />
        </>
      </KnockFeedProvider>
    </Styled>
  );
}
