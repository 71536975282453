import joinPaths from "routes/AppRoutes/joinPaths";

/**
 *
 * @param applicationId ID
 * @returns 'application/{id}
 */
export default function getApplicationByIdEndpoint(applicationId: ID) {
  return joinPaths("application", applicationId);
}
