import * as React from "react";
import { Box, Typography } from "@mui/material";

export default function EmptySearchState() {
  return (
    <Box data-testid="empty-search-state" ml={3} mt={3}>
      <Typography fontWeight="bold" textAlign="center" variant="h5">
        No items match your search.
      </Typography>
      <Typography mt={2} textAlign="center" variant="body1">
        Adjust your search to try again.
      </Typography>
    </Box>
  );
}
