import * as React from "react";
import MobileResponsiveContainer from "components/MobileResponsiveContainer";
import { ApiGet_ApplicationsAllResponse } from "api/endpoints/getAllApplicationsEndpoint";

const BorrowerPortfolioPageDesktop = React.lazy(
  () => import("./BorrowerPortfolioPageDesktop")
);

const BorrowerPortfolioPageMobile = React.lazy(
  () => import("./BorrowerPortfolioPageMobile")
);

export type PortfolioBorrowerPageProps = {
  disbursedLoans: ApiGet_ApplicationsAllResponse;
};

export default function BorrowerPortfolioPageContent({
  disbursedLoans,
}: PortfolioBorrowerPageProps) {
  const contentProps: PortfolioBorrowerPageProps = {
    disbursedLoans,
  };

  return (
    <MobileResponsiveContainer
      desktop={<BorrowerPortfolioPageDesktop {...contentProps} />}
      mobile={<BorrowerPortfolioPageMobile {...contentProps} />}
    />
  );
}
