import { LoaderFunction, RouteObject, To } from "react-router-dom";
import ViewerContext from "context/UserContextV2/ViewerContext";
import BaseRouteController from "routes/BaseRouteController";
import getNavigateConfig from "utilities/getNavigateConfig";
import client from "utilities/apiClient";
import { CapitalizedUserTypes } from "types/UserTypes";
import getExportRequestsEndpoint, {
  ApiGet_ExportRequest,
} from "api/endpoints/get/export/getExportRequestsEndpoint";
import getApplicationEndpoint, {
  ApiGet_ApplicationType,
} from "api/endpoints/get/application/getApplicationEndpoint";
import getQuerySearchParams from "utilities/getQuerySearchParams";
import ApplicationsExportPage from "./ApplicationsExportPage";

export type ApplicationExportPageLoaderData = {
  applicationList: ApiGet_ApplicationType[];
  exportRequests: ApiGet_ExportRequest[];
  selectedExportId?: ID;
};

export default class ApplicationsExportPageRouteController extends BaseRouteController {
  public getRouteConfig(): RouteObject {
    return {
      children: this.getChildren(),
      element: this.getElement(),
      errorElement: this.getErrorElement(),
      loader: this.getLoader(),
      path: this.getPath(),
    };
  }

  public override loader: LoaderFunction = async () => {
    const { exportId } = getQuerySearchParams();

    const [applicationListResponse, exportRequestsResponse] = await Promise.all(
      [
        client<ApiPayload<Array<ApiGet_ApplicationType>>>(
          getApplicationEndpoint()
        ),
        client<ApiPayload<ApiGet_ExportRequest[]>>(getExportRequestsEndpoint()),
      ]
    );
    return {
      applicationList: applicationListResponse.data,
      exportRequests: exportRequestsResponse.data,
      selectedExportId: exportId,
    };
  };

  /**
   * /applications/export
   */
  public static override getRoutePath(): string {
    return "/applications/export";
  }

  /**
   * Returns the full path with the existing url query params
   */
  public static override buildRoutePath(): To {
    return getNavigateConfig(
      ApplicationsExportPageRouteController.getRoutePath()
    );
  }

  public getPath(): string | undefined {
    return "export";
  }

  private VALID_VIEWERS = new Set<CapitalizedUserTypes>([
    CapitalizedUserTypes.LENDER,
    CapitalizedUserTypes.BORROWER,
    CapitalizedUserTypes.PARTNER,
  ]);

  public async genCanUserView(): Promise<boolean> {
    const vc = this.getVC();
    const viewerType = vc.getViewerType();
    return viewerType != null && this.VALID_VIEWERS.has(viewerType);
  }

  public getErrorElement(): JSX.Element | undefined {
    return undefined;
  }

  public getElementImpl(): JSX.Element | undefined {
    return <ApplicationsExportPage />;
  }

  public getChildrenRouteControllers(): (new (
    viewerContext: ViewerContext
  ) => BaseRouteController)[] {
    return [];
  }
}
