import * as React from "react";
import { Box, TableContainer } from "@mui/material";
import { Table, TableBody, TableCell, TableRow } from "components/mui/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import getNavigateConfig from "utilities/getNavigateConfig";
import stylex from "utilities/stylex";
import getApplicationStepOverviewPath from "routes/AppRoutes/applications/getApplicationStepOverviewPath";
import { ApiGet_ApplicationType } from "api/endpoints/get/application/getApplicationEndpoint";
import SortTableHeader, {
  getComparator,
  HeaderCellType,
} from "components/mui/table/SortTableHeader";
import formatDateMMDDYYYY from "utilities/formatDateMMDDYYYY";
import ApplicationTableRowButtonMenu from "../ApplicationTableRowButtonMenu";

const styles = stylex({
  row: {
    borderRadius: "3px",
    cursor: "pointer",
    outline: (theme) => `1px solid ${theme.palette.divider}`,
    textDecoration: "none",
  },
  table: {
    margin: (theme) => theme.spacing(2),
    maxWidth: "95%",
  },
});

type ApplicationTableType = {
  borrowerNames: string;
  dataTestId?: string;
  id: ID;
  leadUserName: string;
  loanProduct: string;
  menu: unknown;
  orgName: string;
  partnerOrg?: string;
  startedAt: Date;
  stepName: string;
  userCode: string;
};

type Props = {
  hasPartners: boolean;
  refetchApplicationList: () => Promise<ApiGet_ApplicationType[]>;
  tableRows: ApplicationTableType[];
};

type LenderTableType = Pick<
  ApplicationTableType,
  | "borrowerNames"
  | "orgName"
  | "loanProduct"
  | "stepName"
  | "startedAt"
  | "leadUserName"
  | "menu"
  | "partnerOrg"
>;

const headerCells: HeaderCellType<LenderTableType>[] = [
  {
    disablePadding: true,
    id: "orgName",
    isNumeric: false,
    label: "Business",
  },
  {
    disablePadding: true,
    id: "borrowerNames",
    isNumeric: false,
    label: "Clients",
  },
  {
    disablePadding: true,
    id: "stepName",
    isNumeric: false,
    label: "Step",
  },
  {
    disablePadding: true,
    id: "leadUserName",
    isNumeric: false,
    label: "Lead",
  },
  {
    disablePadding: true,
    id: "startedAt",
    isNumeric: true,
    label: "Started",
  },
  {
    disablePadding: true,
    id: "loanProduct",
    isNumeric: false,
    label: "Loan Product",
  },
  {
    disablePadding: true,
    id: "partnerOrg",
    isNumeric: false,
    label: "Partners",
  },
  {
    disablePadding: true,
    id: "menu",
    isNumeric: false,
    label: "",
  },
];

const ApplicationLenderTableRow = React.memo(
  function ApplicationLenderTableRow({
    hasPartners,
    refetchApplicationList,
    row,
  }: {
    hasPartners: boolean;
    refetchApplicationList: () => Promise<ApiGet_ApplicationType[]>;
    row: ApplicationTableType;
  }) {
    return (
      <TableRow
        component={Link}
        data-testid={row.dataTestId}
        hover
        sx={styles.row}
        to={getNavigateConfig(getApplicationStepOverviewPath(row.id))}
      >
        <TableCell>{row.orgName}</TableCell>
        <TableCell sx={{ maxWidth: "20rem" }}>{row.borrowerNames}</TableCell>
        <TableCell sx={{ maxWidth: "15rem", minWidth: "10rem" }}>
          {row.stepName}
        </TableCell>
        <TableCell>{row.leadUserName}</TableCell>
        <TableCell>
          {formatDateMMDDYYYY(new Date(row.startedAt).toString())}
        </TableCell>
        <TableCell sx={{ maxWidth: "20rem" }}>{row.loanProduct}</TableCell>
        {hasPartners ? <TableCell>{row.partnerOrg}</TableCell> : null}
        <ApplicationTableRowButtonMenu
          applicationId={row.id}
          refetchApplicationList={refetchApplicationList}
        />
      </TableRow>
    );
  }
);

export default function ApplicationLenderTable({
  hasPartners,
  refetchApplicationList,
  tableRows,
}: Props) {
  const [rows, setRows] = useState<ApplicationTableType[] | null>(
    [...tableRows].sort(
      getComparator("asc", headerCells[0].id, headerCells[0].isNumeric)
    )
  );

  useEffect(() => {
    setRows(
      [...tableRows].sort(
        getComparator("asc", headerCells[0].id, headerCells[0].isNumeric)
      )
    );
  }, [tableRows]);

  const filteredHeaderCells = hasPartners
    ? headerCells
    : headerCells.filter((v) => v.id !== "partnerOrg");

  return (
    <Box>
      <TableContainer sx={{ overflowX: "auto" }}>
        <Table stickyHeader sx={styles.table}>
          <SortTableHeader
            headerCells={filteredHeaderCells}
            rows={tableRows}
            setSortedRows={setRows}
          />
          <TableBody data-testid="application-page-application-table-body">
            {rows?.map((row) => (
              <ApplicationLenderTableRow
                key={`${row.orgName}-${row.id}`}
                hasPartners={hasPartners}
                refetchApplicationList={refetchApplicationList}
                row={row}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
