import * as React from "react";
import { Link } from "react-router-dom";
import { Button, Paper, Stack, Typography } from "@mui/material";
import LoginRouteController from "routes/LoginPage/LoginRouteController";

export default function ResetPasswordSuccessPage() {
  return (
    <Stack alignItems="center" flexGrow={1} justifyContent="center">
      <Stack
        alignItems="center"
        component={Paper}
        elevation={8}
        spacing={1}
        sx={(theme) => ({
          minWidth: "450px",
          padding: 5,
          [theme.breakpoints.down("sm")]: {
            minWidth: "unset",
          },
        })}
      >
        <Typography fontWeight="bold" variant="h5">
          Your Password was Reset!
        </Typography>
        <Typography>Now you can log in to your account.</Typography>
        <Button
          component={Link}
          to={LoginRouteController.buildRoutePath()}
          variant="contained"
        >
          LOG IN
        </Button>
      </Stack>
    </Stack>
  );
}
