/* eslint-disable typescript-sort-keys/string-enum */

enum GKList {
  // Add GK strings here
  EXAMPLE_GK = "example_feature",
  LENDER_SETTINGS_MANAGE_BANK_ACCOUNTS = "lender_settings_manage_bank_accounts",
  WORKFLOW_V2 = "workflow_v2",
  LOAN_DISBURSEMENT = "loan_disbursement",
  LOAN_REPAYMENT = "loan_repayment",

  MULTIPLE_REPAYMENT_BANK_ACCOUNTS = "multiple_repayment_bank_accounts",
  ACTIVITY_LOG = "activity_log",
  LENDER_CLIENT_MESSAGING = "lender_client_messaging",
  SOURCE_OF_FUNDS = "source_of_funds",
  MAINTENANCE_MODE = "maintenance_mode",
  PARTNER_LOAN_VIEWS = "partner_loan_views",
  LENDER_BORROWER_LOAN_VISIBILITY = "lender_borrower_loan_visibility",
  SHEETS = "sheets",
  TERMS_ADJUSTMENT_MODIFICATION = "terms_adjustment_modification",
  ACCEPTING_APPLICANTS = "accepting_applicants",
  ACCOUNTING_ADJUSTMENT_MUTATE = "accounting_adjustment_mutate",
  CREDIT_MEMO = "credit_memo",
  EMAIL_VERIFICATION = "email_verification",
}

export default GKList;
