import * as React from "react";
import { AppBar, Box, Stack, Toolbar, Typography } from "@mui/material";
import NotificationIcon from "components/general/NotificationIcon";
import MobileHamburgerMenu from "./MobileHamburgerMenu";

type Props = {
  left?: JSX.Element | string | null;
  right?: JSX.Element | null;
};

const DefaultRightContent = React.memo(function DefaultRightContent() {
  return (
    <Stack alignItems="center" direction="row">
      <NotificationIcon color="primary" sx={{ padding: 0 }} />
      <MobileHamburgerMenu />
    </Stack>
  );
});

export default React.memo(function MobileNavBar({
  left,
  right = <DefaultRightContent />,
}: Props) {
  let leftContent = left;
  if (typeof leftContent === "string") {
    leftContent = <Typography fontWeight="bold">{leftContent}</Typography>;
  }
  return (
    <AppBar color="inherit" position="sticky">
      <Toolbar
        component={Stack}
        direction="row"
        justifyContent="space-between"
        spacing={1}
      >
        <Box flexBasis={1} flexGrow={1}>
          {leftContent}
        </Box>
        <Box>{right}</Box>
      </Toolbar>
    </AppBar>
  );
});
