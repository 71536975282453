import { useLocation, useParams } from "react-router-dom";

/**
 * Drew inspiration from this solution:
 * https://stackoverflow.com/a/70754791/10183759
 *
 * I don't love this solution because it relies on
 * the order in which the params come in are a string match
 * to what needs to be replaced.
 * This can be an issue for if the url has some value (ie. 1)
 * as a natural part of the url, but a param is also 1.
 * This would wrongfully replace that param
 */
export default function useCurrentPathDefinition() {
  const location = useLocation();
  const params = useParams();
  const { pathname } = location;

  if (Object.keys(params).length === 0) {
    return pathname; // we don't need to replace anything
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue != null) {
      path = path.replace(
        // replaces only within `/`, making it a url param
        new RegExp(`\\/${paramValue}(\\/|\\?|$)`),
        `/:${paramName}$1`
      );
    }
  });
  return path;
}
