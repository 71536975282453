import ViewerContext from "context/UserContextV2/ViewerContext";
import { RouteObject } from "react-router-dom";
import BaseRouteController from "routes/BaseRouteController";
import ApplicationPage, { getApplicationPageLoader } from "./ApplicationsPage";

export default class ApplicationsPageRouteController extends BaseRouteController {
  public getRouteConfig(): RouteObject {
    return {
      element: this.getElement(),
      errorElement: this.getErrorElement(),
      index: true,
      loader: this.getLoader(),
      path: this.getPath(),
      shouldRevalidate: () => true,
    };
  }

  public override loader = getApplicationPageLoader(this.getVC());

  public static override getRoutePath() {
    return undefined;
  }

  public getPath(): undefined {
    return undefined;
  }

  public async genCanUserView(): Promise<boolean> {
    return true;
  }

  public getErrorElement(): JSX.Element | undefined {
    return undefined;
  }

  public getElementImpl(): JSX.Element | undefined {
    return <ApplicationPage />;
  }

  public getChildrenRouteControllers(): (new (
    viewerContext: ViewerContext
  ) => BaseRouteController)[] {
    return [];
  }
}
