/* eslint-disable react/function-component-definition */
import { TableHead as MuiTableHead, TableHeadProps } from "@mui/material";

/**
 * An abstraction around the MuiTableHead component setting the component as a 'div'.
 *
 * Why not override via `defaultProps` in the ThemeProvider?
 * It turns out because MUI's Table has overridable props, only some props
 * are exposed to be overridden, and `component` is not one of them for ALL
 * the table elements.
 */
const TableHead: typeof MuiTableHead = (props: TableHeadProps): JSX.Element => {
  return <MuiTableHead component="div" {...props} />;
};

export default TableHead;
