import encrypt from "./encrypt";

/**
 * Common utility method to handle encrypting values and returning
 * the encrypted value as a string
 * @param {string} value The value that needs encryption
 * @returns string
 */
export default function encryptToString(value: string): string {
  return encrypt(value).toString();
}
