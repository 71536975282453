import * as React from "react";
import { Box, TableSortLabel } from "@mui/material";
import { TableCell, TableHead, TableRow } from "components/mui/table";
import { visuallyHidden } from "@mui/utils";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import useCurrentPathDefinition from "hooks/useCurrentPathDefinition";
import { Statsig } from "statsig-react";
import StatsigEvents from "utilities/statsig/StatsigEvents";
import { defer } from "lodash";

function descendingComparator<T>(
  a: T,
  b: T,
  orderBy: keyof T,
  isNumeric: boolean
) {
  const aValue = isNumeric
    ? a[orderBy]
    : ((a[orderBy] ?? "") as string).toLowerCase();
  const bValue = isNumeric
    ? b[orderBy]
    : ((b[orderBy] ?? "") as string).toLowerCase();

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

export type Order = "asc" | "desc";
export type HeadCellType<T> = {
  disablePadding: boolean;
  id: keyof T;
  isNumeric: boolean;
  label: string;
};

export function getComparator<T>(
  order: Order,
  orderBy: keyof T,
  isNumeric: boolean
): (a: T, b: T) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, isNumeric)
    : (a, b) => -descendingComparator(a, b, orderBy, isNumeric);
}

export type HeaderCellType<T> = {
  disablePadding: boolean;
  id: keyof T;
  isNumeric: boolean;
  label: string;
};

type EnhancedTableProps<T> = {
  headerCells: HeaderCellType<T>[];
  rows: T[];
  setSortedRows: (sortedRows: T[]) => void;
};

export default function SortTableHeader<T>(props: EnhancedTableProps<T>) {
  const { headerCells, rows, setSortedRows } = props;
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof T>(headerCells[0].id);

  const currentPath = useCurrentPathDefinition();

  const createSortHandler = (property: keyof T, isNumeric: boolean) => () => {
    const isAsc = orderBy === property && order === "asc";
    const updatedOrder = isAsc ? "desc" : "asc";
    setOrder(updatedOrder);
    setOrderBy(property);
    const sortedRows = [...rows].sort(
      getComparator(updatedOrder, property, isNumeric)
    );
    const strProperty = String(property);
    defer(() =>
      Statsig.logEvent(StatsigEvents.SORT_TABLE_HEADER, strProperty, {
        currentPath,
        property: strProperty,
      })
    );
    setSortedRows(sortedRows);
  };

  return (
    <TableHead>
      <TableRow data-testid="sort-table-header">
        {headerCells.map((headCell) => (
          <TableCell
            key={String(headCell.id)}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              data-testid="sort-table-label"
              direction={orderBy === headCell.id ? order : "asc"}
              IconComponent={KeyboardArrowUpIcon}
              onClick={createSortHandler(headCell.id, headCell.isNumeric)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
