export type ApiPost_ResetPasswordEndpointBody = {
  email: string;
};

/**
 * @returns account/request-reset-password
 */
export default function getPostResetPasswordEndpoint() {
  return "account/request-reset-password";
}
