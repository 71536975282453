import { useTheme } from "@mui/material";
import useWindowResize from "hooks/useWindowResize";

/**
 * When using this hook, make sure you are handling excessive rerenders
 * as this hook _will_ cause rerenders frequently as the user resizes the window.
 */
export default function useIsMobileScreenSize() {
  const theme = useTheme();
  const windowWidth = useWindowResize();

  return windowWidth <= theme.breakpoints.values.sm;
}
