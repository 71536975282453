const NUMBERS = "0123456789";
const NUMBERS_REVERSED = "9876543210";
const ALPHABET = "abcdefghijklmnopqrstuvqxyz";
const ALPHABET_REVERSED = "zyxqvutsrqponmlkjihgfedcba";

/**
 * Returns a boolean representing whether a string has sequential characters/numbers
 * either ascending (abcd, 1234) or descending (dcba, 4321)
 * @param password string to be checked
 * @param maxCharacters the limit of sequential characters allowed in the string
 */
export default function getHasSequentialCharacters(
  password: string,
  maxCharacters: number
) {
  if (password.length < maxCharacters) return false;

  for (let i = 0; i < password.length; i++) {
    if (i + maxCharacters > password.length) break;

    // This allows us to check our password against our consts
    // for numeric and character sequences. This chunks our password
    // based on the maximum length of repeated or sequential characters,
    // then checks against our constant character and numeric strings.
    const chunk = password.slice(i, i + maxCharacters);
    const isCharacterSequence = ALPHABET.indexOf(chunk) > -1;
    const isNumericSequence = NUMBERS.indexOf(chunk) > -1;
    const isCharacterSequenceReversed = ALPHABET_REVERSED.indexOf(chunk) > -1;
    const isCharacterSequenceUppercase =
      ALPHABET.toUpperCase().indexOf(chunk) > -1;
    const isCharacterSequenceUppercaseReversed =
      ALPHABET_REVERSED.toUpperCase().indexOf(chunk) > -1;
    const isNumericSequenceReversed = NUMBERS_REVERSED.indexOf(chunk) > -1;

    if (
      isNumericSequence ||
      isCharacterSequence ||
      isCharacterSequenceUppercase ||
      isCharacterSequenceReversed ||
      isCharacterSequenceUppercaseReversed ||
      isNumericSequenceReversed
    ) {
      return true;
    }
  }

  return false;
}
