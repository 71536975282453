import { ApiApplicationLeadUser } from "./getApplicationCurrentStepEndpoint";

export type ApiLoanProductType = {
  createdAt: string;
  createdByUserId: ID;
  deletedAt: null;
  description: string | null;
  id: ID;
  lenderId: ID;
  modifiedAt: string | null;
  publishedDate: string | null;
  title: string;
};
type ApiApplicationTaskType = {
  applicationTaskId: ID;
  status: string;
  taskTitle: string;
};

type ApiCurrentStepType = {
  applicationTasks: ApiApplicationTaskType[];
  leadUser?: ApiApplicationLeadUser;
  stepName: string;
};

export type ApiGet_ApplicationType = {
  borrowerId: number;
  borrowerNames: string;
  borrowerOrganizationName: string;
  completedAt: string | null;
  currentStep: ApiCurrentStepType;
  id: ID;
  lenderId: ID;
  loanProduct: ApiLoanProductType;
  loanProductId: ID;
  loanProductName: string;
  partnerOrgs: string;
  startedAt: string;
  workflowId: ID;
};

export type ApiGet_ApplicationResponse = Array<ApiGet_ApplicationType>;

export type ApiGet_PartnerApplicationType = ApiGet_ApplicationType & {
  leadPartner: {
    code: string;
    firstName: string;
    lastName: string;
  };
};

export type ApiGet_PartnerApplicationResponse =
  Array<ApiGet_PartnerApplicationType>;

/**
 *
 * @returns "application"
 */
export default function getApplicationEndpoint() {
  return `application`;
}
