import { RouteObject, To } from "react-router-dom";
import ViewerContext from "context/UserContextV2/ViewerContext";
import BaseRouteController from "routes/BaseRouteController";
import getNavigateConfig from "utilities/getNavigateConfig";
import ResetPasswordPageRouteController from "routes/ResetPasswordPage/ResetPasswordPageRouteController";
import ResetPasswordConfirmPageRouteController from "routes/ResetPasswordPage/ResetPasswordConfirmPageRouteController";
import ResetPasswordSuccessRouteController from "routes/ResetPasswordPage/ResetPasswordSuccessPageRouteController";

export default class ResetPasswordRootRouteController extends BaseRouteController {
  public getRouteConfig(): RouteObject {
    return {
      children: this.getChildren(),
      element: this.getElement(),
      errorElement: this.getErrorElement(),
      loader: this.getLoader(),
      path: this.getPath(),
    };
  }

  /**
   * /reset-password
   */
  public static override getRoutePath(): string {
    return "/reset-password";
  }

  /**
   * Returns the full path with the existing url query params
   */
  public static override buildRoutePath(): To {
    return getNavigateConfig(ResetPasswordRootRouteController.getRoutePath());
  }

  public getPath(): string | undefined {
    return "/reset-password";
  }

  public async genCanUserView(): Promise<boolean> {
    return true;
  }

  public getErrorElement(): JSX.Element | undefined {
    return undefined;
  }

  public getElementImpl(): JSX.Element | undefined {
    return undefined;
  }

  public getChildrenRouteControllers(): (new (
    viewerContext: ViewerContext
  ) => BaseRouteController)[] {
    return [
      ResetPasswordPageRouteController,
      ResetPasswordConfirmPageRouteController,
      ResetPasswordSuccessRouteController,
    ];
  }
}
