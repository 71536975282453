/* eslint-disable react/function-component-definition */
import { TableRow as MuiTableRow, TableRowProps } from "@mui/material";

/**
 * An abstraction around the MuiTableRow component setting the component as a 'div'.
 *
 * Why not override via `defaultProps` in the ThemeProvider?
 * It turns out because MUI's Table has overridable props, only some props
 * are exposed to be overridden, and `component` is not one of them for ALL
 * the table elements.
 */
const TableRow: typeof MuiTableRow = (props: TableRowProps): JSX.Element => {
  return <MuiTableRow component="div" {...props} />;
};
export default TableRow;
