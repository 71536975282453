import { useCallback, useEffect, useRef, useState } from "react";

export default function useBoolean(initialValue = false) {
  const [isTrue, setIsTrue] = useState(initialValue);

  const didMountRef = useRef(false);

  useEffect(() => {
    didMountRef.current = true;
    return () => {
      didMountRef.current = false;
    };
  }, []);

  const setTrue = useCallback(function setTrue() {
    if (didMountRef.current) {
      setIsTrue(true);
    }
  }, []);

  const setFalse = useCallback(function setFalse() {
    if (didMountRef.current) {
      setIsTrue(false);
    }
  }, []);

  const toggle = useCallback(function toggle() {
    if (didMountRef.current) {
      setIsTrue((val) => !val);
    }
  }, []);

  return {
    isTrue,
    setFalse,
    setIsTrue,
    setTrue,
    toggle,
  };
}
