import { useState } from "react";
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Input from "components/mui/Inputs/Input";
import PowerSearch from "components/PowerSearch";
import {
  PowerSearchOptionsMap,
  PowerSearchFilterData,
} from "components/PowerSearch/PowerSearchTypes";
import stylex from "utilities/stylex";
import { ApiPresetFilterResponseModel } from "@presta-technologies-inc/presta-types";
import { Close } from "@mui/icons-material";
import deleteUrlSearchParams from "utilities/deleteUrlSearchParams";
import encryptToString from "utilities/encryption/encryptToString";
import setUrlSearchParams from "utilities/setUrlSearchParams";
import sanitizeFilterSet from "utilities/PowerSearch/sanitizeFilterSet";

const styles = stylex({
  input: {
    margin: (theme) => theme.spacing(3, 2),
    width: "20rem",
  },
});

type ApplicationLenderPageFiltersProps = {
  filterSet: string | null;
  handleFilter: (filterData: PowerSearchFilterData) => void;
  isShowAssignedToMe: boolean;
  onSaveViewClick: () => void;
  powerSearchOptionsMap: PowerSearchOptionsMap;
  presetFilterList?: Array<ApiPresetFilterResponseModel>;
  setIsShowAssignedToMe: (value: boolean) => void;
  setSearchTerm: (value: string) => void;
};

export default function ApplicationLenderPageFilters({
  filterSet,
  handleFilter,
  onSaveViewClick,
  powerSearchOptionsMap,
  presetFilterList,
  setIsShowAssignedToMe,
  setSearchTerm,
}: ApplicationLenderPageFiltersProps) {
  const [selectedPresetFilter, setSelectedPresetFilter] = useState<
    string | null
  >(null);

  function setToQueryParams(filterData: PowerSearchFilterData | null): void {
    if (filterData == null) {
      deleteUrlSearchParams(["query"]);
      return;
    }
    // convert sets to arrays
    const data = Object.keys(filterData).reduce<{
      [key: string]: {
        is: Array<string>;
        isNot: Array<string>;
      };
    }>((accum, key) => {
      return {
        ...accum,
        [key]: {
          ...accum[key],
          is: Array.from(filterData[key]?.is ?? new Set()),
          isNot: Array.from(filterData[key]?.isNot ?? new Set()),
        },
      };
    }, {});
    const query = encryptToString(JSON.stringify(data));
    setUrlSearchParams({
      query,
    });
  }

  function setFilter(presetFilterSet: string | null): void {
    if (presetFilterSet == null) {
      return;
    }
    setSelectedPresetFilter(presetFilterSet ?? null);
    const parsedFilterSet = JSON.parse(presetFilterSet);
    const filterData = Object.keys(
      parsedFilterSet
    ).reduce<PowerSearchFilterData>((acc, key) => {
      return {
        ...acc,
        [key]: {
          is: new Set(parsedFilterSet[key].is),
          isNot: new Set(parsedFilterSet[key].isNot),
        },
      };
    }, {});
    handleFilter(filterData);
    setToQueryParams(filterData);
  }

  function renderPresetFilters() {
    return presetFilterList?.map((presetFilter) => {
      const variant =
        selectedPresetFilter === presetFilter.filterSet ? "filled" : "outlined";
      const parsedFilterSet = sanitizeFilterSet(presetFilter.filterSet);

      const tooltipTitle = parsedFilterSet
        .replace(/:/gim, " ")
        .replace(/;/gim, "; ")
        .replace("stepName", "Step")
        .replace("leadUserName", "Lead")
        .replace("loanProduct", "Loan Product")
        .replace("partnerOrg", "Parter Organization");

      return (
        <Tooltip key={presetFilter.id} placement="bottom" title={tooltipTitle}>
          <Chip
            key={presetFilter.id}
            color="primary"
            label={presetFilter.title}
            onClick={() => setFilter(presetFilter.filterSet)}
            variant={variant}
          />
        </Tooltip>
      );
    });
  }

  function onClearPresetFilter() {
    setSelectedPresetFilter(null);
    setToQueryParams(null);
    window.location.reload();
  }

  return (
    <>
      <Stack alignItems="center" direction="row">
        <Input
          dataTestId="application-page-search-input"
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by business or client name..."
          sx={styles.input}
        />
        <FormGroup sx={{ alignItems: "center", flexDirection: "row" }}>
          <FormControlLabel
            control={
              <Checkbox data-testid="application-table-assigned-filter" />
            }
            label="Assigned to me"
            onChange={(_, checked: boolean) => setIsShowAssignedToMe(checked)}
          />
        </FormGroup>
      </Stack>
      <Stack>
        <Stack
          alignItems="center"
          direction="row"
          spacing={1}
          sx={{ mb: 2, mx: 2 }}
        >
          <Typography>Filter By:</Typography>
          <PowerSearch
            handleFilter={handleFilter}
            optionsMap={powerSearchOptionsMap}
            saveToQueryParams
            sx={{ flexGrow: 1 }}
          />
          <Button
            disabled={filterSet == null}
            onClick={onSaveViewClick}
            variant="contained"
          >
            Save View
          </Button>
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          spacing={1}
          sx={{ mx: 2, overflowX: "scroll" }}
        >
          <Typography>Saved Filters:</Typography>
          <IconButton onClick={onClearPresetFilter}>
            <Close />
          </IconButton>
          {renderPresetFilters()}
        </Stack>
      </Stack>
    </>
  );
}
