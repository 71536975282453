import { CapitalizedUserTypes } from "types/UserTypes";

export type Permission = string;
export type PermissionData = {
  isEnabled: boolean;
  name: Permission;
};

export type User = {
  borrowerId?: ID;
  canRunMetro2Reports?: boolean;
  code: string;
  didAcceptTermsOfServiceAndPrivacyPolicy: boolean;
  email: string;
  firstName: string;
  isAdmin: boolean;
  isBorrower: boolean;
  isEmailVerified: boolean;
  isLender: boolean;
  isPartner: boolean;
  lastName: string;
  lenderId?: ID;
  orgName: string;
  orgType: CapitalizedUserTypes;
  partnerId?: ID;
  permissions?: Array<PermissionData>;
};

export type ApiPost_AuthLoginResponse = {
  applicationId?: ID;
  knockToken: string;
  refreshToken: string;
  token: string;
  user: User;
};

/**
 * @returns auth/login
 */
export default function getAuthLoginEndpoint() {
  return "auth/login";
}
