/* eslint-disable react/function-component-definition */
import { TableBody as MuiTableBody, TableBodyProps } from "@mui/material";

/**
 * An abstraction around the MuiTableBody component setting the component as a 'div'.
 *
 * Why not override via `defaultProps` in the ThemeProvider?
 * It turns out because MUI's Table has overridable props, only some props
 * are exposed to be overridden, and `component` is not one of them for ALL
 * the table elements.
 */
const TableBody: typeof MuiTableBody = (props: TableBodyProps): JSX.Element => {
  return <MuiTableBody component="div" {...props} />;
};

export default TableBody;
