import * as React from "react";
import { useState } from "react";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import OutlinedInput from "components/mui/Inputs/Input";
import { InputType } from "types/InputType";
import EMAIL_REGEX from "utilities/regex/emailRegex";
import getPostResetPasswordEndpoint, {
  ApiPost_ResetPasswordEndpointBody,
} from "api/endpoints/post/resetPassword/getPostResetPasswordEndpoint";
import { useSnackbar } from "context/SnackbarContext";
import useMutation from "hooks/useMutation";
import ResetPasswordLoginButton from "./ResetPasswordLoginButton";

const VALID_EMAIL_TEXT = "Please enter a valid email address";

export default function ResetPasswordPage() {
  const [email, setEmail] = useState("");
  const [didEmailSend, setDidEmailSend] = useState(false);
  const [commitPost] = useMutation({ method: "POST" });
  const { openSnackbar } = useSnackbar();

  const isEmailValid = email !== "" && EMAIL_REGEX.test(email);

  function submitResetEmail(didResend: boolean) {
    const body = {
      email,
    };
    commitPost<{}, ApiPost_ResetPasswordEndpointBody>({
      body,
      endpoint: getPostResetPasswordEndpoint(),
      onCompleted() {
        setDidEmailSend(true);
        if (didResend) {
          openSnackbar("Reset password link sent");
        }
      },
    });
  }

  const renderContent = () => {
    if (didEmailSend) {
      return (
        <>
          <Typography fontWeight="bold" variant="h5">
            Email Sent!
          </Typography>
          <Stack spacing={1}>
            <Box>
              <Typography>
                If your email is affiliated with an account, the reset password
                link was sent to:{" "}
              </Typography>
              <Typography data-testid="email-sent-text" fontWeight="bold">
                {email}
              </Typography>
            </Box>
            <Typography>The link will time out in 24 hours.</Typography>
            <Button
              data-testid="resend-reset-password-button"
              onClick={() => submitResetEmail(didEmailSend)}
              variant="contained"
            >
              RE-SEND PASSWORD RESET LINK
            </Button>
          </Stack>
        </>
      );
    }
    return (
      <>
        <Typography
          data-testid="reset-password-header"
          fontWeight="bold"
          variant="h5"
        >
          Reset password
        </Typography>
        <Typography>
          Enter your email and we will send you a link to reset your password.
        </Typography>
        <OutlinedInput
          data-testid="reset-password-email-input"
          error={email !== "" && !isEmailValid}
          helperText={
            email !== "" && !isEmailValid ? VALID_EMAIL_TEXT : undefined
          }
          label="Email*"
          onChange={(e) => setEmail(e.target.value)}
          sx={{ width: "100%" }}
          type={InputType.EMAIL}
          value={email}
        />
        <Button
          data-testid="send-password-reset-link-button"
          disabled={email === "" || !isEmailValid}
          onClick={() => submitResetEmail(didEmailSend)}
          variant="contained"
        >
          SEND PASSWORD RESET LINK
        </Button>
      </>
    );
  };

  return (
    <Stack alignItems="center" flexGrow={1} justifyContent="center">
      <Stack
        alignItems="center"
        component={Paper}
        elevation={8}
        spacing={1}
        sx={(theme) => ({
          minWidth: "450px",
          padding: theme.spacing(5),
          [theme.breakpoints.down("sm")]: {
            minWidth: "unset",
          },
        })}
      >
        {renderContent()}
        <ResetPasswordLoginButton />
      </Stack>
    </Stack>
  );
}
