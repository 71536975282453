import * as CryptoJS from "crypto-js";
import { AES } from "crypto-js";

const KEY = process.env.ENCRYPTION_KEY ?? "";
/**
 * Common utility method to handle encrypting values.
 * Make sure that your environment variables has the proper encryption key.
 * @param {string} value The value that needs encryption
 * @returns CryptoJS.lib.CipherParams
 */
export default function encrypt(value: string): CryptoJS.lib.CipherParams {
  return AES.encrypt(value, KEY);
}
