import { AppBar, Box, Button, Stack, Toolbar, Typography } from "@mui/material";
import { useUserContextV2 } from "context/UserContextV2";
import EmailVerificationPageContent from "./EmailVerificationPageContent";

export default function EmailVerificationPage() {
  const { logout } = useUserContextV2();
  const header = (
    <Box>
      <AppBar position="relative">
        <Toolbar sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography color="white" component="div">
            <Button color="inherit" onClick={logout} variant="text">
              Log out
            </Button>
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );

  return (
    <Stack flexGrow={1}>
      {header}
      <EmailVerificationPageContent />
    </Stack>
  );
}
