import * as React from "react";
import { Snackbar, SnackbarContent } from "@mui/material";
import { useSnackbar } from "context/SnackbarContext";

const styles = {
  snackbar: {
    fontSize: "1rem",
    justifyContent: "center",
    padding: "0 0.8rem",
  },
};

export default React.memo(function SnackbarItem(): JSX.Element {
  const { closeSnackbar, snackbarState } = useSnackbar();
  const { action, anchorOrigin, autoHideDuration, message, open } =
    snackbarState;
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      data-testid="snackbar"
      onClose={closeSnackbar}
      open={open}
    >
      <SnackbarContent
        action={action}
        data-testid="snackbar-content"
        message={message}
        style={{
          ...styles.snackbar,
          justifyContent: action !== null ? "flex-start" : "center",
        }}
      />
    </Snackbar>
  );
});
