import { QueryClient } from "react-query";

/**
 * ONLY USED FOR react-query.
 * Add configurations here.
 *
 * https://tanstack.com/query/latest/docs/reference/QueryClient
 */
const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

export default queryClient;
