import { atom, atomFamily, selector } from "recoil";
import { PowerSearchFilterData } from "./PowerSearchTypes";

export const selectedPowerSearchValuesAtom = atom<Array<string>>({
  default: [],
  key: "selectedPowerSearchValuesAtom",
});

export const selectedOptionsForValueAtomFamily = atomFamily<
  Set<string>,
  string
>({
  default: new Set(),
  key: "selectedPowerSearchValuesAtom",
});

export const selectedFilterForOptionsAtomFamily = atomFamily<boolean, string>({
  default: true,
  key: "selectedFilterForOptionsAtomFamily",
});

export const powerSearchFilterSelector = selector({
  get({ get, getCallback }) {
    const values = get(selectedPowerSearchValuesAtom);
    return getCallback(({ snapshot }) => {
      return () => {
        const out = values.reduce<PowerSearchFilterData>((accum, value) => {
          const filterOption = snapshot
            .getLoadable(selectedFilterForOptionsAtomFamily(value))
            .getValue();
          const optionValues = snapshot
            .getLoadable(selectedOptionsForValueAtomFamily(value))
            .getValue();
          return {
            ...accum,
            [value]: {
              ...accum[value],
              [filterOption ? "is" : "isNot"]: optionValues,
            },
          };
        }, {});
        if (Object.keys(out).length === 0) {
          return null;
        }
        return out;
      };
    });
  },
  key: "powerSearchFilterSelector",
});
