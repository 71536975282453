import { Statsig } from "statsig-react";

/* eslint-disable no-param-reassign */
const TRUE = "true";
const FALSE = "false";

const GK_SEARCH_QUERY = "gk";
const GK_ENABLE_SEARCH_QUERY = "gk_enable";
const GK_DISABLE_SEARCH_QUERY = "gk_disable";

function getSearchString() {
  return window.location.search;
}

function getGKsFromCommaSeparated(gkString: string) {
  return gkString.split(",");
}

type QueryParamMap = {
  [key: string]: Array<string | boolean>;
};

/**
 *
 * @param map
 * @param key
 * @param value
 */
function addToMap(
  map: QueryParamMap,
  key: string,
  value: (string | boolean) | Array<string | boolean>
) {
  if (map[key] == null) {
    map[key] = [];
  }
  if (Array.isArray(value)) {
    map[key] = [...map[key], ...value];
  } else {
    map[key].push(value);
  }
}

function getSearchParams() {
  const string = getSearchString();
  const sanitized = string.replace(/\?/gim, "");
  const entries = sanitized.split("&");
  const out: QueryParamMap = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const entry of entries) {
    const [key, value] = entry.split("=");
    let valueImpl: string | boolean = value;
    if (value === TRUE || value === FALSE) {
      valueImpl = value === TRUE;
    }
    if (typeof valueImpl === "string") {
      if (valueImpl.includes(",")) {
        const gkArray = getGKsFromCommaSeparated(valueImpl);
        addToMap(out, key, gkArray);
        continue;
      }
    }
    addToMap(out, key, valueImpl);
  }

  return out;
}

/**
 * Hide unreleased or unstable features behind a GateKeeper!
 *
 * There are times we want to merge code to main, but don't want it to be publicly available yet.
 * For instances like this, we can use a GateKeeper.
 *
 * GateKeepers are effectively switches that can turn on or off a feature.
 *
 * This gk method takes in a GateKeeper string, and returns a boolean,
 * indicating if the feature is enabled from the query params.
 *
 *
 * You can chain GKs, either by commas, or by adding more gk query params
 *
 * Example:
 * `/home/?gk=foo,bar`
 * is the same as
 * `/home/?gk=foo&gk=bar`
 *
 *
 * Example usage:
 * ```jsx
 *
 * // url: /foo/bar/?gk=feature_name
 *
 * return (
 *    <div>
 *        <StableFeature />
 *        {gk('feature_name') ? <UnstableFeature /> : null} // UnstableFeature will be visible
 *    </div>
 * )
 * ```
 *
 * @param gkString The GK you want to enable
 * @param allowForDev will allows enable the GK in dev environment. Opt OUT by explicitly passing `false`
 * @returns
 */
export default function gk(
  gkString: string,
  allowForDev = process.env.NODE_ENV === "development"
): boolean {
  const searchParams = getSearchParams();

  const gks = [
    ...(searchParams[GK_SEARCH_QUERY] ?? []),
    ...(searchParams[GK_ENABLE_SEARCH_QUERY] ?? []),
  ];
  const disabledGKs = searchParams[GK_DISABLE_SEARCH_QUERY] ?? [];

  const gkSet = new Set(gks);
  const disabledGKSet = new Set(disabledGKs);

  // We have the option to override Statsig _ONLY LOCALLY_ here.
  // This will still log an exposure to Statsig, but for development purposes,
  // we will be able to override the GK.

  // You can also get around this by whitelisting yourself (either via email or id)
  // in Statsig.
  // As we move forward with Statsig, we will likely want to handle whitelisting
  // on the statsig surface instead of in-code
  if (disabledGKSet.has(gkString)) {
    return false;
  }
  const isManualOverride = allowForDev || gkSet.has(gkString);
  try {
    return Statsig.checkGate(gkString) || isManualOverride;
  } catch (e) {
    // if Statsig is not properly initialized, we'll catch and allow our existing
    // manual overrides to work.
    // This is likely to happen if the client api key is nullish
    return isManualOverride;
  }
}
