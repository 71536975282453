import React from "react";

const getKeys = (obj, idx) => {
  return Object.keys(obj)
    [idx].split(/(?=[A-Z])/)
    .join(" ")
    .toLocaleString()
    .toLocaleLowerCase();
};

const getValues = (obj, idx) => {
  return Object.values(obj)[idx].toLocaleString();
};

const getSnackBarMessage = (obj, numOfChanges) => {
  if (numOfChanges === 0) {
    return "You have not made any updates";
  }
  let msg = "";
  switch (numOfChanges) {
    case 0:
      msg = <span> have not made any updates</span>;
      break;
    case 1:
      msg = (
        <span>
          You updated your {getKeys(obj, 0)} to
          <b> {getValues(obj, 0)}</b>
        </span>
      );
      break;
    case 2:
      msg = (
        <span>
          You updated your {getKeys(obj, 0)} to <b>{getValues(obj, 0)}</b> and
          your {getKeys(obj, 1)} to <b>{getValues(obj, 1)}</b>
        </span>
      );
      break;
    case 3:
      msg = (
        <span>
          You updated your {getKeys(obj, 0)} to <b>{getValues(obj, 0)}</b>, your{" "}
          {getKeys(obj, 1)} to <b>{getValues(obj, 1)}</b>, and your{" "}
          {getKeys(obj, 2)} to <b>{getValues(obj, 2)}</b>
        </span>
      );
      break;
    default:
      break;
  }
  return msg;
};

export default getSnackBarMessage;
