import { ApiPartner } from "@presta-technologies-inc/presta-types";
import joinPaths from "routes/AppRoutes/joinPaths";

export type ApiGet_PartnerOrgsResponse = Array<ApiPartner>;

/**
 * @param lenderId ID
 * @returns partner-lender/lender/{lenderId}
 */
export default function getPartnerOrgsByLenderIdEndpoint(lenderId: ID) {
  return joinPaths("partner-lender", "lender", lenderId);
}
