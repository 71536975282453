import { PowerSearchFilterData } from "./PowerSearchTypes";

export default function powerSearchFilterItems<
  T extends {
    [key: string]: unknown;
  }
>(powerSearchFilterData: PowerSearchFilterData | null, items: Array<T>) {
  if (powerSearchFilterData == null) {
    return items;
  }

  const keys = Object.keys(powerSearchFilterData);

  const hasIncludes = keys.some(
    (key) => powerSearchFilterData[key].is?.size > 0
  );

  let filteredItems = items;

  for (const key of keys) {
    filteredItems = filteredItems.filter((item) => {
      const { is, isNot } = powerSearchFilterData[key];
      const shouldInclude = Boolean(is?.has(String(item[key])));
      const shouldExclude = Boolean(isNot?.has(String(item[key])));
      if (shouldExclude) {
        return false;
      }
      if (!hasIncludes) {
        return true;
      }
      return shouldInclude;
    });
  }

  return filteredItems;
}
