import * as React from "react";
import { useEffect, useReducer } from "react";
import { useSnackbar } from "context/SnackbarContext";
import { usePrestaCookies } from "context/PrestaCookiesContext";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import OutlinedInput from "components/mui/Inputs/Input";
import { User } from "api/endpoints/auth/login";
import useMutation from "hooks/useMutation";
import getAccountEndpoint, {
  ApiPut_AccountBody,
} from "api/endpoints/put/getAccountEndpoint";
import EMAIL_REGEX from "utilities/regex/emailRegex";
import useIsMobileScreenSize from "hooks/useIsMobileScreenSize";
import { useUserContextV2 } from "context/UserContextV2";
import getSnackBarMessage from "./getSnackBarMessage";

type State = {
  email: string;
  firstName: string;
  lastName: string;
};

type Action =
  | {
      payload: { user: User };
      type: "reset";
    }
  | {
      payload: {
        firstName: string;
      };
      type: "setFirstName";
    }
  | {
      payload: {
        lastName: string;
      };
      type: "setLastName";
    }
  | {
      payload: {
        email: string;
      };
      type: "setEmail";
    };

function getInitialState(user: User) {
  return {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
  };
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "setFirstName":
      return {
        ...state,
        firstName: action.payload.firstName,
      };
    case "setLastName":
      return {
        ...state,
        lastName: action.payload.lastName,
      };
    case "setEmail":
      return {
        ...state,
        email: action.payload.email,
      };
    case "reset":
      return getInitialState(action.payload.user);
    default:
      throw new Error("Unhandled action type");
  }
}

type Props = {
  active: boolean;
  close(): void;
};

export default function EditProfileModal({ active, close }: Props) {
  const isMobileScreenSize = useIsMobileScreenSize();

  const { viewerContext } = useUserContextV2();

  const [commitPut] = useMutation({ method: "PUT" });
  const { setCookie } = usePrestaCookies();
  const user = viewerContext.getUserX();
  const [state, dispatch] = useReducer(reducer, user, getInitialState);

  useEffect(() => {
    dispatch({ payload: { user: viewerContext.getUserX() }, type: "reset" });
  }, [viewerContext]);

  const { email, firstName, lastName } = state;

  const { openSnackbar } = useSnackbar();

  const firstNameHasError = firstName.length === 0;
  const lastNameHasError = lastName.length === 0;
  const emailHasError = email.length === 0 || !EMAIL_REGEX.test(email);
  const isSaveButtonDisabled =
    firstNameHasError || lastNameHasError || emailHasError;

  function submitChanges(): void {
    const itemsChanged = Object.fromEntries(
      (Object.entries(state) as Array<[keyof State, string]>).filter(
        (item) => item[1] !== user[item[0]]
      )
    );
    const numOfChanges = Object.entries(itemsChanged).length;
    const msg = getSnackBarMessage(itemsChanged, numOfChanges);
    if (!numOfChanges) {
      close();
      openSnackbar(msg);
      return;
    }

    commitPut<Partial<User>, ApiPut_AccountBody>({
      body: {
        ...itemsChanged,
        code: user.code,
      },
      endpoint: getAccountEndpoint(),
      onCompleted(response) {
        if (response.status === "success") {
          const params = {
            ...user,
            ...itemsChanged,
          };

          viewerContext.setUser(params);
          // TODO - deprecate using cookies for user data
          setCookie("user", params, {
            maxAge: 3600,
            path: "/",
          });
          close();
          openSnackbar(msg);
        }
      },
    });
  }

  return (
    <Dialog
      data-testid="edit-profile-modal"
      fullScreen={isMobileScreenSize}
      fullWidth
      maxWidth="sm"
      open={active}
    >
      <DialogTitle>Edit Your Profile</DialogTitle>

      <DialogContent>
        <OutlinedInput
          dataTestId="edit-profile-first-name"
          error={firstNameHasError}
          helperText={firstNameHasError ? "Please enter first name" : ""}
          label="First Name"
          onChange={(e) => {
            dispatch({
              payload: {
                firstName: e.target.value,
              },
              type: "setFirstName",
            });
          }}
          placeholder="First name"
          value={firstName}
        />
        <OutlinedInput
          dataTestId="edit-profile-last-name"
          error={lastNameHasError}
          helperText={lastNameHasError ? "Please enter last name" : ""}
          label="Last Name"
          onChange={(e) => {
            dispatch({
              payload: {
                lastName: e.target.value,
              },
              type: "setLastName",
            });
          }}
          placeholder="Last name"
          value={lastName}
        />
        <OutlinedInput
          dataTestId="edit-profile-email"
          error={emailHasError}
          helperText={emailHasError ? "Please enter a valid email address" : ""}
          label="Email"
          onChange={(e) => {
            dispatch({
              payload: {
                email: e.target.value,
              },
              type: "setEmail",
            });
          }}
          placeholder="Email"
          value={email}
        />
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="edit-profile-save-button"
          disabled={isSaveButtonDisabled}
          onClick={submitChanges}
          variant="contained"
        >
          Save
        </Button>
        <Button
          data-testid="edit-profile-cancel-button"
          onClick={() => {
            close();
            dispatch({ payload: { user }, type: "reset" });
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
