export default function formatDateMMDDYYYY(dateString: string) {
  if (dateString.length === 0) {
    return "";
  }
  const date = new Date(dateString);
  const month = date.getMonth() + 1; // months are 0-indexed
  const day = date.getDate();
  const year = date.getFullYear();

  const output = `${month}/${day}/${year}`;
  const isDateValid =
    !Number.isNaN(month) || !Number.isNaN(day) || !Number.isNaN(year);
  if (isDateValid) {
    return output;
  }
  return "";
}
