import { useLoaderData as useLoaderDataImpl } from "react-router-dom";

/**
 * Sadly, react-router-dom doesn't have an out-of-the-box type safe
 * way to handle loader data.
 * This hook is just a wrapped implementation that will typecast the data type
 */
export default function useLoaderData<TData>() {
  return useLoaderDataImpl() as TData;
}
