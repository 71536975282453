import * as React from "react";
import useBoolean from "hooks/useBoolean";
import MobileResponsiveContainer from "components/MobileResponsiveContainer";

export type BusinessSettingsPageProps = {
  closeInviteModal: () => void;
  isInviteTeamMemberModalOpen: boolean;
  openInviteModal: () => void;
};

const BusinessSettingsContent = React.lazy(
  () => import("./BusinessSettingsContent")
);

const BusinessSettingsPageMobileView = React.lazy(
  () => import("./mobile/BusinessSettingsPageMobileView")
);

export default function BusinessSettingsPage() {
  const {
    isTrue: isInviteModalOpen,
    setFalse: closeInviteModal,
    setTrue: openInviteModal,
  } = useBoolean();

  const props = {
    closeInviteModal,
    isInviteTeamMemberModalOpen: isInviteModalOpen,
    openInviteModal,
  };

  return (
    <MobileResponsiveContainer
      desktop={<BusinessSettingsContent {...props} />}
      mobile={<BusinessSettingsPageMobileView {...props} />}
    />
  );
}
