import { useCallback, useState } from "react";
import client, { Config } from "utilities/apiClient";
import queryClient from "utilities/queryClient";

type Endpoint = string; // TODO create endpoint enum or union type

type UseMutationConfig = {} & Partial<Config>;

export type CommitFn = <TData, TBody>(config: {
  body?: TBody;
  endpoint: string | Endpoint;
  onCompleted?: (resp: ApiPayload<TData>) => void;
  onError?: (error: Error) => void;
  queryKey?: string;
}) => void;

/**
 *
 * Returns a method to handle POST api calls.
 * Can be used for other method types
 *
 * @example
 * ```jsx
 * type Data = {
 *  // include api data types here
 * }
 *
 * type Body = {
 *  hello: string;
 * }
 *
 *
 * const [data, setData] = useState<Data>(null);
 * const [commit, isInFlight] = useMutation<Data>();
 *
 * function handleClick() {
 *    commit<Body>({
 *      endpoint: '/foo/bar',
 *      body: {
 *        tee: 'tee',
 *        hee: 'hee',
 *      },
 *      onComplete(resp) {
 *        setData(data);
 *      },
 *      onError(error) {
 *        console.log(error);
 *      }
 *    })
 * }
 *
 * return (
 *  <div>
 *    <button onClick={handleClick}>
 *      {isInFlight ? <Loader /> : "Send Data"}
 *    </button>
 *  </div>
 * )
 * ```
 *
 * @param config
 * @returns
 */
export default function useMutation(
  config: UseMutationConfig = {}
): [CommitFn, boolean] {
  const [isInFlight, setIsInFlight] = useState(false);

  const commit: CommitFn = useCallback(
    <TData, TBody>({
      body,
      endpoint,
      onCompleted,
      onError,
      queryKey,
    }: {
      body?: TBody;
      endpoint: string | Endpoint;
      onCompleted?: (resp: ApiPayload<TData>) => void;
      onError?: (error: Error) => void;
      queryKey?: string;
    }) => {
      setIsInFlight(true);
      queryClient.invalidateQueries(endpoint);
      client<ApiPayload<TData>>(endpoint, {
        body,
        method: "POST",
        queryKey,
        staleTime: 0,
        ...config,
      })
        .then((resp) => {
          setIsInFlight(false);
          if (resp?.status === "failure" || resp?.status === "error") {
            throw new Error(resp.response);
          }
          onCompleted?.(resp);
        })
        .catch((error) => onError?.(error));
    },
    [config]
  );

  return [commit, isInFlight];
}
