import { Logout, Menu as MenuIcon } from "@mui/icons-material";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import EditProfileModal from "components/general/editProfile/EditProfileModal";
import { useUserContextV2 } from "context/UserContextV2";
import useBoolean from "hooks/useBoolean";
import { Link } from "react-router-dom";
import ApplicationsPageRootRouteController from "routes/ApplicationsPage/ApplicationsPageRootRouteController";
import BorrowerPortfolioPageRouteController from "routes/BorrowerPortfolioPage/BorrowerPortfolioPageRouteController";
import BusinessSettingsPageRouteController from "routes/BusinessSettingsPage/BusinessSettingsPageRouteController";

export default function MobileHamburgerMenu() {
  const { isTrue, setFalse, setTrue } = useBoolean();
  const {
    isTrue: isEditProfileModalOpen,
    setFalse: closeEditProfileModal,
    setTrue: openEditProfileModal,
  } = useBoolean();

  const { logout } = useUserContextV2();

  return (
    <>
      <IconButton
        aria-label="Open application menu"
        color="primary"
        onClick={setTrue}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="top" onClose={setFalse} open={isTrue}>
        <List>
          <ListItem
            component={Link}
            to={ApplicationsPageRootRouteController.buildRoutePath()}
          >
            <ListItemText primary="Application Dashboard" />
          </ListItem>
          <ListItem
            component={Link}
            to={BorrowerPortfolioPageRouteController.buildRoutePath()}
          >
            <ListItemText primary="Loan Portfolio" />
          </ListItem>
          <ListItem>
            <ListItemButton
              disableGutters
              onClick={() => {
                openEditProfileModal();
                setFalse();
              }}
            >
              <ListItemText primary="Edit Your Profile" />
            </ListItemButton>
          </ListItem>
          <ListItem
            component={Link}
            to={BusinessSettingsPageRouteController.buildRoutePath()}
          >
            <ListItemText primary="Business Settings" />
          </ListItem>
          <ListItem>
            <ListItemButton
              disableGutters
              onClick={logout}
              sx={{
                "*:not(:last-child)": {
                  marginRight: 1,
                },
                justifyContent: "flex-start",
              }}
            >
              <ListItemText primary="Log Out" sx={{ flexGrow: 0 }} />
              <ListItemIcon>
                <Logout color="primary" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <EditProfileModal
        active={isEditProfileModalOpen}
        close={closeEditProfileModal}
      />
    </>
  );
}
