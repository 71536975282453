import * as React from "react";
import {
  Box,
  Container,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import useBoolean from "hooks/useBoolean";
import { createPortal } from "react-dom";
import stylex from "utilities/stylex";
import { useContext, useEffect, useMemo, useState } from "react";
import PrestaLogo from "static_assets/svg/PrestaLogo.png";

const styles = stylex({
  hide: {
    opacity: 0,
    transform: "scale(0)",
    visibility: "hidden",
  },
  root: {
    background: (theme) => theme.palette.background.paper,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    transition: (theme) =>
      theme.transitions.create(["visibility", "transform", "opacity"], {
        easing: theme.transitions.easing.easeInOut,
      }),
    visibility: "visible",
    zIndex: 10000,
  },
});

export type Props = {
  content?: JSX.Element;
  loadingMessage?: JSX.Element | string;
  minLoadTimeMs?: number;
  showLoader?: boolean;
};

const FullScreenLoaderContext = React.createContext<
  | undefined
  | {
      fullScreenLoaderProps: Props;
      setFullScreenLoaderProps: React.Dispatch<React.SetStateAction<Props>>;
    }
>(undefined);

export function useFullScreenLoaderContext() {
  const context = useContext(FullScreenLoaderContext);
  if (context == null) {
    throw new Error(
      "useFullScreenLoaderContext must be used within its provider"
    );
  }
  return context;
}

function FullScreenLoaderImpl() {
  const {
    fullScreenLoaderProps: {
      content: customContent,
      loadingMessage,
      minLoadTimeMs = 2000,
      showLoader = false,
    },
  } = useFullScreenLoaderContext();

  const { isTrue: isLoading, setFalse, setTrue } = useBoolean(showLoader);

  useEffect(() => {
    if (showLoader) {
      setTrue();
    }
  }, [setTrue, showLoader]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!showLoader) {
        setFalse();
      }
    }, minLoadTimeMs);
    return () => {
      clearTimeout(timeout);
    };
  }, [minLoadTimeMs, setFalse, showLoader]);

  const defaultContent = (
    <Container
      component={Stack}
      maxWidth="sm"
      spacing={2}
      sx={{ textAlign: "center" }}
    >
      <Box height={200}>
        <img alt="Presta Logo" height="100%" src={PrestaLogo} />
      </Box>
      {loadingMessage == null ? (
        <Typography>Please wait while your updates are saved.</Typography>
      ) : (
        loadingMessage
      )}
      <Box>
        <LinearProgress />
      </Box>
    </Container>
  );

  const content = customContent ?? defaultContent;

  return createPortal(
    <Box sx={[styles.root, isLoading ? null : styles.hide]}>{content}</Box>,
    document.body
  );
}
export default function FullScreenLoaderProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [loaderProps, setLoaderProps] = useState<Props>({});

  const contextValue = useMemo(() => {
    return {
      fullScreenLoaderProps: loaderProps,
      setFullScreenLoaderProps: setLoaderProps,
    };
  }, [loaderProps]);

  return (
    <FullScreenLoaderContext.Provider value={contextValue}>
      {children}
      <FullScreenLoaderImpl />
    </FullScreenLoaderContext.Provider>
  );
}
