import * as React from "react";
import { Check, Close } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import getHasRepeatedCharacters from "utilities/PasswordUtils/getHasRepeatedCharacters";
import getHasSequentialCharacters from "utilities/PasswordUtils/getHasSequentialCharacters";

const MIN_PASSWORD_LENGTH = 12;
const MAX_PASSWORD_LENGTH = 64;
const MAX_REPETITIVE_CHARACTERS = 4;

type Props = {
  password: string;
};

export default function PasswordValidation({ password }: Props) {
  const isMinLength = password.length >= MIN_PASSWORD_LENGTH;

  const isMaxLength = password.length <= MAX_PASSWORD_LENGTH;

  const hasLetters = /[a-zA-Z]/.test(password);

  const hasNumbers = /[0-9]/.test(password);

  // requires password has either a non-word character (!@#$%^&*()*~,.\/?-+=) or an underscore (_)
  const hasSpecialCharacter = /\W|_/g.test(password);

  const isSequential = getHasSequentialCharacters(
    password,
    MAX_REPETITIVE_CHARACTERS
  );

  const isRepeated = getHasRepeatedCharacters(
    password,
    MAX_REPETITIVE_CHARACTERS
  );

  function renderIcon(isValid: boolean) {
    if (isValid) {
      return <Check color="success" fontSize="small" />;
    }
    return <Close color="error" fontSize="small" />;
  }

  const validationParams = [
    {
      id: "maxLength",
      label: "be 64 characters or less",
      valid: isMaxLength,
    },
    {
      id: "minLength",
      label: `be at least ${MIN_PASSWORD_LENGTH} characters long`,
      valid: isMinLength,
    },
    {
      id: "includesLetters",
      label: "include at least one letter",
      valid: hasLetters,
    },
    {
      id: "includesNumbers",
      label: "include at least one number",
      valid: hasNumbers,
    },
    {
      id: "noSeqChars",
      label: "have no more than 3 sequential characters (1234)",
      valid: !isSequential,
    },
    {
      id: "noRepetitiveChars",
      label: "have no more than 3 repetitive characters (aaaa)",
      valid: !isRepeated,
    },
    {
      id: "includesSpecialChar",
      label: "include at least one special character (i.e., !, @, *)",
      valid: hasSpecialCharacter,
    },
  ];

  return (
    <Stack>
      <Typography>Your password must:</Typography>
      {validationParams.map((param) => {
        return (
          <Box key={param.id} alignContent="center" display="flex">
            {renderIcon(param.valid)}
            <Typography variant="caption">{param.label}</Typography>
          </Box>
        );
      })}
    </Stack>
  );
}
