import { StatsigProvider as StatsigProviderImpl } from "statsig-react";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";
import { useUserContextV2 } from "./UserContextV2";

export default function StatsigProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const { viewerContext } = useUserContextV2();
  const user = viewerContext.getUser();

  if (process.env.REACT_APP_STATSIG_API_KEY == null) {
    return children;
  }

  let tier;
  const runAsTier = process.env.REACT_APP_RUNAS_NODE_ENV ?? "";
  if (runAsTier?.length > 0) {
    tier = runAsTier;
  } else {
    tier = process.env.NODE_ENV ?? "development";
  }

  return (
    <ErrorBoundary componentName={StatsigProvider.name} fallback={children}>
      <StatsigProviderImpl
        options={{
          environment: {
            tier,
          },
        }}
        sdkKey={process.env.REACT_APP_STATSIG_API_KEY}
        user={{
          email: user?.email,
          userID: user?.code,
        }}
        waitForInitialization
      >
        {children}
      </StatsigProviderImpl>
    </ErrorBoundary>
  );
}
