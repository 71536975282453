import { RouteObject, To } from "react-router-dom";
import BaseRouteController from "routes/BaseRouteController";
import joinPaths from "routes/AppRoutes/joinPaths";
import getNavigateConfig from "utilities/getNavigateConfig";
import LoginPage from "./LoginPage";

export default class LoginRouteController extends BaseRouteController {
  /**
   * Used to indicate where to redirect after successful login
   */
  public static readonly QUERY_PARAM = "next";

  public getRouteConfig(): RouteObject {
    return {
      element: this.getElement(),
      errorElement: this.getErrorElement(),
      path: this.getPath(),
    };
  }

  public async genCanUserView(): Promise<boolean> {
    return true;
  }

  /**
   * "/login/:lenderId?"
   */
  public static override getRoutePath(lenderId?: ID) {
    let out = "/login";
    if (lenderId != null) {
      out = joinPaths(out, lenderId);
    }
    return out;
  }

  /**
   * Returns the full path with the existing url query params
   */
  public static override buildRoutePath(): To {
    return getNavigateConfig(LoginRouteController.getRoutePath());
  }

  public getPath(): string | undefined {
    return "/login/:lenderId?";
  }

  public getErrorElement(): JSX.Element | undefined {
    return undefined;
  }

  public getElementImpl(): JSX.Element | undefined {
    return <LoginPage />;
  }

  public override getChildrenRouteControllers() {
    return [];
  }
}
