enum InputType {
  ADDRESS = "address",
  EMAIL = "email",
  NUMBER = "number",
  PASSWORD = "password",
  SSN = "ssn",
  TELEPHONE = "tel",
  TEXT = "text",
}

enum SecondaryInputType {
  CURRENCY = "currency",
  EDITDOCTITLE = "editDocTitle",
  PERCENT = "percent",
}

export { InputType, SecondaryInputType };
