import { LoaderFunction, RouteObject, To, defer } from "react-router-dom";
import ViewerContext from "context/UserContextV2/ViewerContext";
import BaseRouteController from "routes/BaseRouteController";
import getNavigateConfig from "utilities/getNavigateConfig";
import isLoanRepaymentGKEnabled from "utilities/gatekeeper/gkChecks/isLoanRepaymentGKEnabled";
import getAllApplicationsEndpoint, {
  ApiGet_ApplicationsAllResponse,
} from "api/endpoints/getAllApplicationsEndpoint";
import client from "utilities/apiClient";
import BorrowerPortfolioPage from "./BorrowerPortfolioPage";

export type BorrowerPortfolioPageLoaderData = {
  allApplicationsPromise: Promise<ApiPayload<ApiGet_ApplicationsAllResponse>>;
};

export default class BorrowerPortfolioPageRouteController extends BaseRouteController {
  public getRouteConfig(): RouteObject {
    return {
      children: this.getChildren(),
      element: this.getElement(),
      errorElement: this.getErrorElement(),
      loader: this.getLoader(),
      path: this.getPath(),
    };
  }

  public override loader: LoaderFunction = async () => {
    const allApplicationsPromise = client<
      ApiPayload<ApiGet_ApplicationsAllResponse>
    >(getAllApplicationsEndpoint());

    return defer({
      allApplicationsPromise,
    });
  };

  public static override getRoutePath(): "/portfolio" {
    return "/portfolio";
  }

  /**
   * Returns the full path with the existing url query params
   */
  public static override buildRoutePath(): To {
    return getNavigateConfig(
      BorrowerPortfolioPageRouteController.getRoutePath()
    );
  }

  public getPath(): "/portfolio" {
    return "/portfolio";
  }

  public async genCanUserView(): Promise<boolean> {
    return this.viewerContext.getIsBorrower() && isLoanRepaymentGKEnabled();
  }

  public getErrorElement(): undefined {
    return undefined;
  }

  public getElementImpl(): JSX.Element {
    return <BorrowerPortfolioPage />;
  }

  public getChildrenRouteControllers(): (new (
    viewerContext: ViewerContext
  ) => BaseRouteController)[] {
    return [];
  }
}
