import * as React from "react";
import { useState } from "react";
import getApplicationEndpoint, {
  ApiGet_ApplicationType,
} from "api/endpoints/get/application/getApplicationEndpoint";
import useLoaderData from "hooks/useLoaderData";
import useGetWithLoader from "hooks/useGetWithLoader";
import client from "utilities/apiClient";
import MobileResponsiveContainer from "components/MobileResponsiveContainer";

const ApplicationBorrowerPageContentDesktop = React.lazy(
  () => import("./ApplicationBorrowerPageContentDesktop")
);

const ApplicationBorrowerPageContentMobile = React.lazy(
  () => import("./mobile/ApplicationBorrowerPageContentMobile")
);

type ApplicationPageLoaderData = {
  applicationList: Array<ApiGet_ApplicationType>;
};

export type ApplicationBorrowerPageProps = {
  items: Array<{
    id: ID;
    loanProduct: string;
    menu: null;
    orgName: string;
    startedAt: Date;
    stepName: string;
  }>;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
};

export const applicationBorrowerPageLoader = async () => {
  const { data } = await client<ApiPayload<Array<ApiGet_ApplicationType>>>(
    getApplicationEndpoint()
  );

  return { applicationList: data };
};

export default function ApplicationBorrowerPageContent() {
  const [searchTerm, setSearchTerm] = useState("");

  const { applicationList: applicationListPageData } =
    useLoaderData<ApplicationPageLoaderData>();

  const { data: applicationList } = useGetWithLoader<ApiGet_ApplicationType[]>(
    getApplicationEndpoint(),
    applicationListPageData
  );

  function getSearchedItems(
    formattedItems: Array<{
      id: ID;
      loanProduct: string;
      menu: null;
      orgName: string;
      startedAt: Date;
      stepName: string;
    }>
  ) {
    if (searchTerm.length > 0) {
      return formattedItems.filter((item) =>
        item.loanProduct.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return formattedItems;
  }

  function formatItemsData() {
    const formattedItems = applicationList.map((item) => {
      return {
        id: item.id,
        loanProduct: item.loanProductName,
        menu: null,
        orgName: item.borrowerOrganizationName,
        startedAt: new Date(item.startedAt),
        stepName: item.currentStep.stepName,
      };
    });

    return getSearchedItems(formattedItems);
  }

  const items = formatItemsData();

  const contentProps: ApplicationBorrowerPageProps = {
    items,
    searchTerm,
    setSearchTerm,
  };

  return (
    <MobileResponsiveContainer
      desktop={<ApplicationBorrowerPageContentDesktop {...contentProps} />}
      mobile={<ApplicationBorrowerPageContentMobile {...contentProps} />}
    />
  );
}
