import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import {
  ApiPresetFilterCreateModel,
  ApiPresetFilterResponseModel,
  PresetFilterList,
} from "@presta-technologies-inc/presta-types";
import getPresetFiltersEndpoint from "api/endpoints/getPresetFiltersEndpoint";
import Input from "components/mui/Inputs/Input";
import useMutation from "hooks/useMutation";
import { useRevalidator } from "react-router-dom";

type Props = {
  closeModal(): void;
  filterSet: string | null;
  open: boolean;
};

export default function ApplicationLenderPageCreateViewModal({
  closeModal,
  filterSet,
  open,
}: Props) {
  const [commitPost] = useMutation();
  const [name, setName] = useState<string | null>(null);
  const { revalidate } = useRevalidator();

  function handleSaveView() {
    if (name === null || filterSet === null) {
      throw new Error("name and filterSet must be defined to save a view");
    }
    commitPost<ApiPresetFilterResponseModel, ApiPresetFilterCreateModel>({
      body: { filterSet, list: PresetFilterList.Applications, title: name },
      endpoint: getPresetFiltersEndpoint(),
      onCompleted: () => {
        setName(null);
        closeModal();
        revalidate();
      },
    });
  }

  return (
    <Dialog fullWidth onClose={closeModal} open={open}>
      <DialogTitle>Save View</DialogTitle>
      <DialogContent>
        <Typography>
          Saving this view will allow you to quickly reuse this filter set in
          the future. Give the view a name and click save.
        </Typography>
        <Input
          label="View Name"
          onChange={(e) => {
            setName(e.target.value);
          }}
          type="text"
          value={name}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>CANCEL</Button>
        <Button
          disabled={name == null || name.length === 0}
          onClick={handleSaveView}
          variant="contained"
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
