export type ApiPost_NewPasswordEndpointBody = {
  password: string;
  resetCode: string;
};

/**
 * @returns account/reset-password
 */
export default function getPostNewPasswordEndpoint() {
  return "account/reset-password";
}
