import * as React from "react";
import { Link } from "react-router-dom";
import { InputType } from "types/InputType";
import { Paper, Stack, Typography } from "@mui/material";
import LoadingButton from "components/LoadingButton";
import stylex from "utilities/stylex";
import OutlinedInput from "components/mui/Inputs/Input";
import PasswordInput from "components/PasswordInput";
import Image from "mui-image";
import ResetPasswordRootRouteController from "routes/ResetPasswordPage/ResetPasswordRootRouteController";

const styles = stylex({
  linkText: { pt: 1, textDecoration: "underline" },
});

export type Props = {
  email: string;
  emailError?: boolean | null;
  errorMessage: string;
  handleChange(value: string, inputType: InputType): void;
  handleSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  isInFlight: boolean;
  lenderThumbnailURL: string | null;
  password: string;
  passwordError?: boolean;
  setPassword(newPassword: string): void;
  toggleIsInFlight: () => void;
};

export default function LoginPageContent({
  email,
  emailError,
  errorMessage,
  handleChange,
  handleSubmit,
  isInFlight,
  lenderThumbnailURL,
  password,
  passwordError,
  setPassword,
  toggleIsInFlight,
}: Props) {
  function handleSubmitWrapper(
    submitEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    toggleIsInFlight();
    handleSubmit(submitEvent);
  }

  return (
    <Stack alignItems="center" flexGrow={1} justifyContent="center">
      <Stack
        alignItems="center"
        component={Paper}
        elevation={8}
        spacing={1}
        sx={(theme) => ({
          minWidth: "450px",
          padding: theme.spacing(5),
          [theme.breakpoints.down("sm")]: {
            minWidth: "unset",
          },
        })}
      >
        {/* Lender Logo */}
        {lenderThumbnailURL != null && lenderThumbnailURL.length > 0 ? (
          <div>
            <Image
              bgColor="transparent"
              duration={0}
              height="200px"
              src={lenderThumbnailURL}
              title="Lender Logo"
            />
          </div>
        ) : null}
        <Typography fontWeight="bold" variant="h5">
          Log into your account
        </Typography>
        <Stack alignItems="center" component="form" spacing={1} width="100%">
          {/* Email */}
          <OutlinedInput
            dataTestId="login-email-input"
            error={emailError ?? undefined}
            label="Email*"
            onChange={(e) => handleChange(e.target.value, InputType.EMAIL)}
            placeholder="Enter email"
            sx={{ width: "100%" }}
            type={InputType.EMAIL}
            value={email}
          />

          {/* Password */}
          <PasswordInput
            dataTestId="login-password-input"
            error={passwordError}
            label="Password*"
            onChange={(e) => {
              const { value } = e.target;
              setPassword(value);
              handleChange(value, InputType.PASSWORD);
            }}
            placeholder="Enter password"
            sx={{ width: "100%" }}
            value={password}
          />
          {errorMessage !== "" ? (
            <Typography color="error" data-testid="login-error-text">
              {errorMessage}
            </Typography>
          ) : null}

          {/* Login Button */}
          <Stack sx={{ width: "100%" }}>
            <LoadingButton
              disabled={isInFlight}
              isLoading={isInFlight}
              onClick={(submitEvent) => handleSubmitWrapper(submitEvent)}
              type="submit"
              variant="contained"
            >
              LOG IN
            </LoadingButton>
          </Stack>
          <Typography
            color="primary"
            component={Link}
            data-testid="forgot-password-link"
            sx={styles.linkText}
            to={ResetPasswordRootRouteController.buildRoutePath()}
          >
            Forgot password?
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
