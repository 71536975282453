/* eslint-disable no-param-reassign */
const TRUE = "true";
const FALSE = "false";

type QueryParamMap = {
  [key: string]: string | boolean | undefined;
};

/**
 *
 * @returns A map of the query search params array from the url
 *
 * Example:
 * ```ts
 * // url.com/?foo=bar&hello=world&isValid=true
 *
 * type QueryParams = {
 *  foo: string;
 *  hello: string;
 *  isValid: boolean;
 * }
 *
 * const searchParams = getQuerySearchParams<QueryParams>();
 * =>
 * {
 *   foo: "bar",
 *   hello: "world",
 *   isValid: true // <= Note, this is type boolean, not type string
 * }
 * ```
 */
export default function getQuerySearchParams<TParams = QueryParamMap>() {
  const string = window.location.search;
  const sanitized = string.replace(/\?/gim, "");
  const entries = sanitized.split("&");
  const out: QueryParamMap = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const entry of entries) {
    const [key, value] = entry.split("=");
    let valueImpl: string | boolean = value;
    if (value === TRUE || value === FALSE) {
      valueImpl = value === TRUE;
    }
    out[key] = valueImpl;
  }

  return out as TParams;
}
