import { defer, LoaderFunction, RouteObject, To } from "react-router-dom";
import ViewerContext from "context/UserContextV2/ViewerContext";
import client from "utilities/apiClient";
import BaseRouteController from "routes/BaseRouteController";
import getNavigateConfig from "utilities/getNavigateConfig";
import getBusinessByBorrowerIdEndpoint, {
  ApiGet_BusinessByBorrowerIdResponse,
} from "api/endpoints/get/business/getBusinessByBorrowerIdEndpoint";
import getBusinessFundingSourceLinkTokenEndpoint, {
  ApiGet_BusinessFundingSourceLinkTokenResponse,
} from "api/endpoints/getBusinessFundingSourceLinkTokenEndpoint";
import getBusinessFundingSourcesByBusinessIdEndpoint, {
  ApiGet_BusinessFundingSourcesByBusinessIdResponse,
} from "api/endpoints/getBusinessFundingSourcesByBusinessIdEndpoint";
import getBusinessPendingFundingSourcesByBusinessIdEndpoint, {
  ApiGet_BusinessPendingFundingSourcesResponse,
} from "api/endpoints/getBusinessPendingFundingSourcesByBusinessIdEndpoint";
import { ApiGet_AutoPayConfiguration } from "api/endpoints/getAutoPayConfigureEndpoint";
import getAutoPayEndpoint from "api/endpoints/getAutoPayEndpoint";
import getBusinessUsersEndpoint from "api/endpoints/getBusinessUsersEndpoint";
import {
  ApiPublicUserModel,
  ApiTeamResponseModel,
} from "@presta-technologies-inc/presta-types";
import BusinessSettingsPage from "./BusinessSettingsPage";

export type BusinessSettingsPageLoaderData = {
  autoPayConfig: ApiGet_AutoPayConfiguration | undefined;
  businessBorrowerByBorrowerIdResponse: ApiPayload<ApiGet_BusinessByBorrowerIdResponse>;
  businessFundingSourceLinkTokenPromise: Promise<
    ApiPayload<ApiGet_BusinessFundingSourceLinkTokenResponse>
  >;
  businessFundingSourcesPromise: Promise<
    ApiPayload<ApiGet_BusinessFundingSourcesByBusinessIdResponse>
  >;
  businessPendingFundingSourcesPromise: Promise<
    ApiPayload<ApiGet_BusinessPendingFundingSourcesResponse>
  >;
  usersPromise: Promise<ApiPayload<ApiPublicUserModel>>;
};
export default class BusinessSettingsPageRouteController extends BaseRouteController {
  public static readonly QUERY_PARAM_SECTION = "section";

  public static readonly CLIENTS = "clients";

  public static readonly BANK_ACCOUNTS = "bank-accounts";

  public static readonly CONFIGURE_AUTO_PAY = "configure-auto-pay";

  public static readonly BUSINESS = "business";

  public static readonly PARTNERS = "partners";

  public getRouteConfig(): RouteObject {
    return {
      children: this.getChildren(),
      element: this.getElement(),
      errorElement: this.getErrorElement(),
      loader: this.getLoader(),
      path: this.getPath(),
      shouldRevalidate: () => true,
    };
  }

  public override loader: LoaderFunction = async () => {
    const usersPromise = await client<ApiPayload<ApiTeamResponseModel>>(
      getBusinessUsersEndpoint()
    );

    const viewerContext = this.getVC();
    const borrowerId = viewerContext.getBorrowerId();
    if (borrowerId == null) {
      throw new Error(
        "borrowerId must be defined to view Account Settings Page"
      );
    }
    const businessBorrowerByBorrowerIdResponse = await client<
      ApiPayload<ApiGet_BusinessByBorrowerIdResponse>
    >(getBusinessByBorrowerIdEndpoint(borrowerId));

    const businessFundingSourceLinkTokenPromise = client<
      ApiPayload<ApiGet_BusinessFundingSourceLinkTokenResponse>
    >(getBusinessFundingSourceLinkTokenEndpoint());

    const businessFundingSourcesPromise = client<
      ApiPayload<ApiGet_BusinessFundingSourcesByBusinessIdResponse>
    >(
      getBusinessFundingSourcesByBusinessIdEndpoint(
        businessBorrowerByBorrowerIdResponse.data.id
      )
    );

    const autoPayConfigResp = await client<
      ApiPayload<ApiGet_AutoPayConfiguration>
    >(getAutoPayEndpoint());

    const businessPendingFundingSourcesPromise = client<
      ApiPayload<ApiGet_BusinessPendingFundingSourcesResponse>
    >(
      getBusinessPendingFundingSourcesByBusinessIdEndpoint(
        businessBorrowerByBorrowerIdResponse.data.id
      )
    );

    return defer({
      autoPayConfig: autoPayConfigResp?.data ?? undefined,
      businessBorrowerByBorrowerIdResponse,
      businessFundingSourceLinkTokenPromise,
      businessFundingSourcesPromise,
      businessPendingFundingSourcesPromise,
      usersPromise,
    });
  };

  /**
   * /account
   */
  public static override getRoutePath(): string {
    return "/account";
  }

  public static override buildRoutePath(): To {
    return getNavigateConfig(
      BusinessSettingsPageRouteController.getRoutePath()
    );
  }

  public getPath(): string | undefined {
    return "/account";
  }

  public async genCanUserView(): Promise<boolean> {
    return this.getVC().getIsBorrower();
  }

  public getErrorElement(): JSX.Element | undefined {
    return undefined;
  }

  public getElementImpl(): JSX.Element | undefined {
    return <BusinessSettingsPage />;
  }

  public getChildrenRouteControllers(): (new (
    viewerContext: ViewerContext
  ) => BaseRouteController)[] {
    return [];
  }
}
