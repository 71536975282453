import * as React from "react";
import {
  FormHelperText,
  InputLabel,
  OutlinedInput as MuiOutlinedInput,
  OutlinedInputProps,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useId } from "react";

export type Props = {
  contextText?: string;
  dataTestId?: string;
  helperText?: string | JSX.Element;
  id?: string;
  label?: string;
  textAlign?: string;
} & OutlinedInputProps;

const OutlinedInputImpl = styled(
  MuiOutlinedInput,
  {}
)(({ theme }) => ({
  "& fieldset": {
    borderColor: theme.palette.primary.light,
  },
  "label + &": {
    marginTop: theme.spacing(2.5),
  },
}));

/**
 *
 * An abstraction of MUI's Outlined Input.
 * Our designs require the label to sit on top of the input
 * See https://mui.com/material-ui/react-text-field/#components
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default React.forwardRef<any, Props>(function OutlinedInput(props, ref) {
  const {
    contextText,
    dataTestId,
    helperText,
    id,
    label,
    sx,
    textAlign,
    ...outlinedInputProps
  } = props;

  const uid = useId();

  const htmlForId = id ?? uid;

  const getHelperText = () => {
    if (helperText == null) {
      return null;
    }
    return (
      <FormHelperText
        data-testid="input-helper-text"
        error={outlinedInputProps.error}
      >
        {helperText}
      </FormHelperText>
    );
  };

  const labelEl =
    label != null ? (
      <InputLabel
        data-testid="input-label"
        htmlFor={htmlForId}
        shrink
        sx={{
          fontSize: (theme) => theme.typography.h6,
          position: "relative",
          top: (theme) => theme.spacing(1),
          whiteSpace: "normal",
        }}
      >
        {label}
      </InputLabel>
    ) : null;

  return (
    <Stack
      ref={ref}
      sx={{
        width: "100%",
        ...sx,
      }}
    >
      {labelEl}
      <Typography
        data-testid="input-context-text"
        fontWeight="light"
        variant="caption"
      >
        {contextText}
      </Typography>
      <OutlinedInputImpl
        data-testid={dataTestId ?? "text-input"}
        id={htmlForId}
        margin="none"
        {...outlinedInputProps}
        sx={{
          background: (theme) => theme.palette.background.paper,
          textAlign: textAlign ?? "left",
          width: "100%",
        }}
      />
      {getHelperText()}
    </Stack>
  );
});
