import * as React from "react";
import { useState } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { Button, Paper, Stack, Typography } from "@mui/material";
import PasswordInput from "components/PasswordInput";
import PasswordValidation from "components/general/PasswordValidation";
import getIsValidPassword from "utilities/PasswordUtils/getIsValidPassword";
import useLoaderData from "hooks/useLoaderData";
import getPostNewPasswordEndpoint, {
  ApiPost_NewPasswordEndpointBody,
} from "api/endpoints/post/resetPassword/getPostNewPasswordEndpoint";
import LoadingButton from "components/LoadingButton";
import useMutation from "hooks/useMutation";
import ResetPasswordRootRouteController from "routes/ResetPasswordPage/ResetPasswordRootRouteController";
import ResetPasswordLoginButton from "routes/ResetPasswordPage/ResetPasswordLoginButton";
import ResetPasswordSuccessRouteController from "./ResetPasswordSuccessPageRouteController";

type ResetPasswordConfirmData = {
  passwordData: {
    isValid: boolean;
  };
};

export default function ResetPasswordConfirmPage() {
  const [password, setPassword] = useState("");
  const [commitPost, isInFlight] = useMutation({ method: "POST" });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(window.location.search);
  const code = searchParams.get("code");

  const { passwordData } = useLoaderData<ResetPasswordConfirmData>();

  function submitNewPassword() {
    if (code != null) {
      const body = {
        password,
        resetCode: code,
      };
      commitPost<{}, ApiPost_NewPasswordEndpointBody>({
        body,
        endpoint: getPostNewPasswordEndpoint(),
        onCompleted(response) {
          if (response.status === "success") {
            navigate(ResetPasswordSuccessRouteController.getRoutePath());
          }
        },
      });
    }
  }

  const isValidPassword = getIsValidPassword(password);

  const renderContent = () => {
    if (passwordData.isValid) {
      return (
        <>
          <Typography fontWeight="bold" variant="h5">
            Reset password
          </Typography>
          <Typography>Create a new password for your account:</Typography>
          <PasswordInput
            error={false}
            label="Password*"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            value={password}
          />
          <PasswordValidation password={password} />
          <LoadingButton
            disabled={isInFlight || !isValidPassword}
            isLoading={isInFlight}
            onClick={submitNewPassword}
            variant="contained"
          >
            RESET PASSWORD
          </LoadingButton>
        </>
      );
    }

    return (
      <>
        <Typography fontWeight="bold" variant="h5">
          This link has expired
        </Typography>
        <Typography>
          It has been over 24 hours since you initiated the request for this
          reset password link.
        </Typography>
        <Button
          component={Link}
          data-testid="expired-password-new-link-button"
          to={ResetPasswordRootRouteController.buildRoutePath()}
          variant="contained"
        >
          REQUEST NEW LINK
        </Button>
      </>
    );
  };

  return (
    <Stack alignItems="center" flexGrow={1} justifyContent="center">
      <Stack
        alignItems="center"
        component={Paper}
        elevation={8}
        spacing={1}
        sx={(theme) => ({
          minWidth: "450px",
          padding: theme.spacing(5),
          [theme.breakpoints.down("sm")]: {
            minWidth: "unset",
          },
        })}
      >
        {renderContent()}
        <ResetPasswordLoginButton />
      </Stack>
    </Stack>
  );
}
