import { ApiApplicationResponseModel } from "@presta-technologies-inc/presta-types";
import joinPaths from "routes/AppRoutes/joinPaths";

export type ApiGet_ApplicationsAllResponse = Array<ApiApplicationResponseModel>;

/**
 * @returns application/all
 */
export default function getAllApplicationsEndpoint() {
  return joinPaths("application", "all");
}
