import { ApiPendingBusinessFundingSource } from "@presta-technologies-inc/presta-types";
import joinPaths from "routes/AppRoutes/joinPaths";

export type ApiGet_BusinessPendingFundingSourcesResponse =
  Array<ApiPendingBusinessFundingSource>;

/**
 * @returns business-funding-source/pending/business/:businessId
 */
export default function getBusinessPendingFundingSourcesByBusinessIdEndpoint(
  businessId: ID
) {
  return joinPaths(
    "business-funding-source",
    "pending",
    "business",
    businessId
  );
}
