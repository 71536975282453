import { Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

export type StylesInput =
  | SystemStyleObject<Theme>
  | ((theme: Theme) => SystemStyleObject<Theme>);

export type StyleXConfig = {
  [name: string]: StylesInput;
};

/**
 * This method is mostly syntatic sugar to provide stronger typing
 * to defined styles for MUI.
 *
 * It's a low-key rip off from Facebook's stylex, which is a pretty cool read if you get the chance
 * https://stylexjs.com/
 *
 * We have it in Presta because this was before Facebook open sourced stylex and I didn't know what
 * else to name it at the time.
 */
export default function stylex<T extends StyleXConfig>(config: T) {
  return config;
}
