import * as React from "react";
import useLoaderData from "hooks/useLoaderData";
import { Await } from "react-router-dom";
import { ApiGet_ApplicationsAllResponse } from "api/endpoints/getAllApplicationsEndpoint";
import Skeleton from "components/Skeleton";
import { ApplicationStatus } from "@presta-technologies-inc/presta-types";
import { BorrowerPortfolioPageLoaderData } from "./BorrowerPortfolioPageRouteController";
import BorrowerPortfolioPageContent from "./BorrowerPortfolioPageContent";

function BorrowerPortfolioPageFallback() {
  return (
    <>
      {Array.from({ length: 5 }, (_, idx) => {
        return (
          <Skeleton
            key={`borrower-portfolio-page-skeleton-${idx}`}
            height="70px"
          />
        );
      })}
    </>
  );
}

export default function BorrowerPortfolioPage() {
  const { allApplicationsPromise } =
    useLoaderData<BorrowerPortfolioPageLoaderData>();
  return (
    <React.Suspense fallback={<BorrowerPortfolioPageFallback />}>
      <Await resolve={allApplicationsPromise}>
        {({ data }: ApiPayload<ApiGet_ApplicationsAllResponse>) => {
          const disbursedLoans = data.filter((application) => {
            return application.status === ApplicationStatus.Disbursed;
          });
          return (
            <BorrowerPortfolioPageContent disbursedLoans={disbursedLoans} />
          );
        }}
      </Await>
    </React.Suspense>
  );
}
