import * as React from "react";
import { useEffect } from "react";
import Hotjar from "@hotjar/browser";
import "./App.css";
import AppProvider from "context/AppProvider";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";
import ErrorBoundaryDefaultFallback from "components/errorBoundary/ErrorBoundaryDefaultFallback";
import useCheatCode from "hooks/useCheatCode";

const AppContent = React.lazy(() => import("./AppContent"));

const hotJarSiteId = Number(process.env.REACT_APP_HOTJAR_SITE_ID);
const hotjarVersion = Number(process.env.REACT_APP_HOTJAR_VERSION);

function App() {
  useCheatCode();
  useEffect(() => {
    Hotjar.init(hotJarSiteId, hotjarVersion, {
      debug: process.env.NODE_ENV === "development",
    });
  }, []);

  return (
    // TODO - add fallback
    <React.Suspense fallback={null}>
      <AppProvider>
        <ErrorBoundary
          componentName={App.name}
          fallback={<ErrorBoundaryDefaultFallback />}
        >
          <AppContent />
        </ErrorBoundary>
      </AppProvider>
    </React.Suspense>
  );
}

export default App;
