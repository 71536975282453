import * as CryptoJS from "crypto-js";
import { AES } from "crypto-js";

const KEY = process.env.ENCRYPTION_KEY ?? "";
/**
 * Common utility method to decrypt an encrypted value from our `encrypt` utility method
 * @param {string | CryptoJS.lib.CipherParams} cipherText The payload from `encrypt` to decrypt
 * @returns string
 */
export default function decryptToString(
  cipherText: string | CryptoJS.lib.CipherParams
) {
  return AES.decrypt(cipherText, KEY).toString(CryptoJS.enc.Utf8);
}
