import * as React from "react";
import { Typography } from "@mui/material";
import MobileNavBar from "components/navbar/mobile/MobileNavBar";
import LoginPageContent, { Props } from "../LoginPageContent";

export default function LoginMobilePage(props: Props) {
  return (
    <>
      <MobileNavBar
        left={<Typography fontWeight="bold">Log In</Typography>}
        right={null}
      />
      <LoginPageContent {...props} />
    </>
  );
}
