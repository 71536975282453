import { RouteObject, To } from "react-router-dom";
import ViewerContext from "context/UserContextV2/ViewerContext";
import BaseRouteController from "routes/BaseRouteController";
import getNavigateConfig from "utilities/getNavigateConfig";
import ApplicationsPageRouteController from "routes/ApplicationsPage/ApplicationsPageRouteController";
import ApplicationsExportPageRouteController from "routes/ApplicationsPage/ApplicationsExportPage/ApplicationsExportPageRouteController";
import { CapitalizedUserTypes } from "types/UserTypes";

export default class ApplicationsPageRootRouteController extends BaseRouteController {
  public getRouteConfig(): RouteObject {
    return {
      children: this.getChildren(),
      element: this.getElement(),
      errorElement: this.getErrorElement(),
      loader: this.getLoader(),
      path: this.getPath(),
    };
  }

  /**
   *  /applications
   */
  public static override getRoutePath(): string {
    return "/applications";
  }

  /**
   * Returns the full path with the existing url query params
   */
  public static override buildRoutePath(): To {
    return getNavigateConfig(
      ApplicationsPageRootRouteController.getRoutePath()
    );
  }

  public getPath(): string | undefined {
    return "applications";
  }

  private VALID_VIEWERS = new Set<CapitalizedUserTypes>([
    CapitalizedUserTypes.LENDER,
    CapitalizedUserTypes.BORROWER,
    CapitalizedUserTypes.PARTNER,
  ]);

  public async genCanUserView(): Promise<boolean> {
    const vc = this.getVC();
    const viewerType = vc.getViewerType();
    return viewerType != null && this.VALID_VIEWERS.has(viewerType);
  }

  public getErrorElement(): undefined {
    return undefined;
  }

  public getElementImpl(): undefined {
    return undefined;
  }

  public getChildrenRouteControllers(): (new (
    viewerContext: ViewerContext
  ) => BaseRouteController)[] {
    return [
      ApplicationsPageRouteController,
      ApplicationsExportPageRouteController,
    ];
  }
}
