import { LoaderFunction, LoaderFunctionArgs, redirect } from "react-router-dom";
import AppRoutes from "routes/AppRoutes/AppRoutes";
import CookieUtils from "utilities/CookieUtils";

/**
 * DEPRECATE in favor of the BaseRouteController automatically handling this
 *
 * Use this method if you need a loader function, but you want to automatically
 * check if the user is logged in via cookies
 */
export default function buildLoaderFunction<TData>(
  loaderFunction: LoaderFunction,
  redirectPath = AppRoutes.LOGIN
) {
  return async (args: LoaderFunctionArgs) => {
    const token = CookieUtils.getCookie<string>("token");
    if ((token?.length ?? 0) > 0) {
      return (await loaderFunction(args)) as TData;
    }
    return redirect(redirectPath);
  };
}
