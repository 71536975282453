type SearchParams = {
  [key: string]: string;
};

/**
 * Updates the url search params, but DOES NOT CAUSE A RERENDER
 *
 * IF YOU NEED TO RERENDER AS A RESULT OF UPDATING THE SEARCH PARAMS, CONSIDER USING `useSearchParams`
 */
export default function setUrlSearchParams(newParams: SearchParams) {
  const { pathname, search } = window.location;
  const newSearchParams = new URLSearchParams(search);
  Object.entries(newParams).forEach(([param, value]) => {
    newSearchParams.set(param, value);
  });
  const newSearch = `?${newSearchParams.toString()}`;
  window.history.pushState({}, "", `${pathname}${newSearch}`);
}
