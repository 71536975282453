import joinPaths from "routes/AppRoutes/joinPaths";

export type ApiGet_BusinessFundingSourceLinkTokenResponse = {
  expiration: string;
  linkToken: string;
};

/**
 * @returns business-funding-source/linkToken
 */
export default function getBusinessFundingSourceLinkTokenEndpoint() {
  return joinPaths("business-funding-source", "linkToken");
}
