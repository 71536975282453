/* eslint-disable typescript-sort-keys/string-enum */
/**
 * This was the original way we used to define routes. This is no longer being used anywhere except old code
 * that would use these paths for navigation.
 * As you find instances of this enum, remove in favor of using the RouteController path methods.
 * @deprecated
 */
enum AppRoutes {
  HOME = "/",
  LOGIN = "/login",
  SIGN_UP = "/signup",
  // /signup/:lenderId/:loanProductId
  SIGN_UP_LENDER_LOAN_PRODUCT = ":lenderId/:loanProductId",
  RESET_PASSWORD = "/reset-password",

  APPLICATIONS = "/applications",

  APPLICATION = "/application",
  APPLICATION_STEP_OVERVIEW = "/application/:applicationId",
  // "/application/:applicationId/settings"
  APPLICATION_SETTINGS = "settings",

  // /application/:applicationId/contract
  CONTRACT = "contract",
  // /application/:applicationId/prepare/:applicationTaskContractId
  PREPARE_CONTRACT = "prepare/:applicationTaskContractId",
  // /application/:applicationId/sign/:applicationTaskContractId
  SIGN_CONTRACT = "sign/:applicationTaskContractId",

  // "/application/:applicationId/grade-rubric/:applicationTaskRubricId"
  GRADE_RUBRIC = "grade-rubric/:applicationTaskRubricId",

  RUBRICS = "/rubrics",
  RUBRIC = "/rubric",
  RUBRIC_BUILDER = "/rubric/builder",
  RUBRIC_BUILDER_ID = "/rubric/builder/:rubricId",

  WORKFLOW = "/workflow",
  WORKFLOWS = "/workflows",
  WORKFLOW_BUILDER = "/workflow/:workflowId",

  // /workflow/:workflowId/configure-criteria/:rubricId
  CONFIGURE_CRITERIA_ID = "configure-criteria/:rubricId",
  CONFIGURE_CRITERIA = "configure-criteria",

  MANAGE_RUBRICS = "/workflow/manage-rubrics",
  MANAGE_RUBRICS_WORKFLOWID = "/workflow/manage-rubrics/:workflowId",

  // "/workflow/:workflowId/manage-contracts"
  MANAGE_CONTRACTS = "manage-contracts",
  // "/workflow/:workflowId/manage-contracts/:workflowContractId/preview"
  PREVIEW_CONTRACT = ":workflowContractId/preview",
  // "/workflow/:workflowId/manage-contracts/:workflowContractId"
  BUILD_CONTRACT = ":workflowContractId",

  DOCUMENT = "/document",
  DOCUMENTS = "/documents",

  RESOURCE = "/resource",
  RESOURCES = "/resources",

  LOAN_PRODUCTS = "/loan-product",
  // /loan-product/:loanProductId
  LOAN_PRODUCT_ID = ":loanProductId",
}

export default AppRoutes;
