/**
 * Returns a boolean representing whether a string has repeated characters/numbers
 * to the limit specified
 * @param password string to be checked
 * @param maxCharacters the limit of repeated characters allowed in the string
 */
export default function getHasRepeatedCharacters(
  password: string,
  maxCharacters: number
) {
  if (password.length < maxCharacters) return false;

  for (let i = 0; i < password.length; i++) {
    if (i + maxCharacters > password.length) break;

    const chunk = password.slice(i, i + maxCharacters);

    if ([...chunk].every((v) => v === chunk[0])) {
      return true;
    }
  }

  return false;
}
