import { useEffect, useRef, useState } from "react";

const CODE =
  "ArrowUpArrowUpArrowDownArrowDownArrowLeftArrowRightArrowLeftArrowRightbaEnter";

export default function useCheatCode(): boolean {
  const [isOn, setIsOn] = useState(false);

  const codeRef = useRef("");

  useEffect(() => {
    function handleKeydown(event: KeyboardEvent): void {
      codeRef.current += event.key;
      if (!CODE.startsWith(codeRef.current)) {
        codeRef.current = event.key;
      }
      if (codeRef.current === CODE) {
        const pyro = document.createElement("div");
        pyro.className = "pyro";
        const before = document.createElement("div");
        before.className = "before";
        const after = document.createElement("div");
        after.className = "after";
        pyro.appendChild(before);
        pyro.appendChild(after);
        document.body.appendChild(pyro);
        setIsOn(true);
      }
    }
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  return isOn;
}
