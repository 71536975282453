import * as React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";

type Props = {
  children?: React.ReactNode;
  secondaryTitle?: string;
  title: string;
};

export default function PageHeader(props: Props) {
  const { children, secondaryTitle, title } = props;
  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mx={2}
      >
        <Box alignItems="flex-end" display="flex" mb={2} mt={1}>
          <Typography
            data-testid="page-header-title"
            fontWeight="bold"
            variant="h4"
          >
            {title}
          </Typography>
          <Typography
            color="grey"
            data-testid="secondary-title"
            mb={0.5}
            ml={1}
            variant="body2"
          >
            {secondaryTitle}
          </Typography>
        </Box>
        <Stack alignContent="flex-end" direction="row" spacing={1}>
          {children}
        </Stack>
      </Box>
      <Divider />
    </>
  );
}
