import { useEffect } from "react";
import { Statsig } from "statsig-react";
import useCurrentPathDefinition from "hooks/useCurrentPathDefinition";
import StatsigEvents from "./StatsigEvents";

const LOGGING_CACHE = new Set();

/**
 * This component should only be used on the BaseRouteController.
 * Its purpose is to log the current surface's url to Statsig as a visited page.
 * We used the defined cache above to prevent excessive logging of the same route.
 * This is beceause with our React Router infra, parent routes also get mounted and
 * would log here, but we only want to log the complete route once.
 */
export default function StatsigPageLog({
  children,
}: {
  children: JSX.Element;
}) {
  const path = useCurrentPathDefinition();
  useEffect(() => {
    if (!LOGGING_CACHE.has(path)) {
      LOGGING_CACHE.add(path);
      Statsig.logEvent(StatsigEvents.PAGE_VISIT, path);
      setTimeout(() => {
        LOGGING_CACHE.delete(path);
      }, 1000);
    }
  }, [path]);
  return children;
}
