import {
  Box,
  Button,
  Container,
  Link as MuiLink,
  Typography,
  useTheme,
} from "@mui/material";
import useWindowResize from "hooks/useWindowResize";
import Image from "mui-image";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import AppRoutes from "routes/AppRoutes/AppRoutes";
import ImageError from "static_assets/svg/ErrorPageImage.png";
import getNavigateConfig from "utilities/getNavigateConfig";
import stylex from "utilities/stylex";

const EMAIL = "support@letspresta.com";
const styles = stylex({
  buttonsContainer: (theme) => ({
    [theme.breakpoints.between("xs", "sm")]: {
      gap: theme.spacing(5),
    },
  }),
  container: (theme) => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.only("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  }),
  infoContainer: (theme) => ({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
      textAlign: "left",
    },
    [theme.breakpoints.only("xs")]: {
      justifyContent: "center",
      marginTop: theme.spacing(5),
      textAlign: "center",
    },
  }),
  largeImage: (theme) => ({
    display: "block",
    width: "300px",
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  }),
  msgFont: (theme) => ({
    fontSize: "h2.fontSize",
    fontWeight: "bold",
    textAlign: "left",
    [theme.breakpoints.only("xs")]: {
      fontSize: "h4.fontSize",
      marginLeft: theme.spacing(2),
    },
  }),
  smallImage: (theme) => ({
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  }),
});

export default function PageNotFoundPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const windowWidth = useWindowResize();
  const isLargeScreen = windowWidth >= theme.breakpoints.values.sm;
  const buttonText = isLargeScreen
    ? "Return to Application Dashboard"
    : "Return to Dashboard";
  return (
    <Container sx={styles.container}>
      <Box sx={styles.largeImage}>
        <Image alt="error robot standing" height="50%" src={ImageError} />
      </Box>
      <Box sx={styles.infoContainer}>
        <Box alignItems="center" display="flex">
          <Box sx={styles.smallImage}>
            <Image alt="error robot standing" height="50%" src={ImageError} />
          </Box>
          <Typography color="primary" sx={styles.msgFont}>
            Sorry, we were unable to find that page.
          </Typography>
        </Box>

        <Box sx={styles.buttonsContainer}>
          <Button onClick={() => navigate(-1)} variant="contained">
            return to previous page
          </Button>
          <Button component={Link} to={getNavigateConfig(AppRoutes.HOME)}>
            {buttonText}
          </Button>
        </Box>

        <Typography variant="body1">
          Contact us for additional support:{" "}
          <MuiLink color="primary" href={`mailto: ${EMAIL}`}>
            {EMAIL}
          </MuiLink>
        </Typography>
      </Box>
    </Container>
  );
}
