import * as React from "react";
import { useUserContextV2 } from "context/UserContextV2";
import { CapitalizedUserTypes } from "types/UserTypes";
import ViewerContext from "context/UserContextV2/ViewerContext";
import { applicationBorrowerPageLoader } from "./ApplicationBorrowerPageContent";
import { getApplicationLenderPageLoader } from "./ApplicationLenderPageContent";
import { applicationPartnerPageLoader } from "./ApplicationPartnerPageContent";

const ApplicationLenderPageContent = React.lazy(
  () => import("./ApplicationLenderPageContent")
);
const ApplicationBorrowerPageContent = React.lazy(
  () => import("./ApplicationBorrowerPageContent")
);
const ApplicationPartnerPageContent = React.lazy(
  () => import("./ApplicationPartnerPageContent")
);

export function getApplicationPageLoader(viewerContext: ViewerContext) {
  switch (viewerContext.getViewerType()) {
    case CapitalizedUserTypes.LENDER:
      return getApplicationLenderPageLoader(viewerContext);
    case CapitalizedUserTypes.BORROWER:
      return applicationBorrowerPageLoader;
    case CapitalizedUserTypes.PARTNER:
      return applicationPartnerPageLoader;
    default:
      return undefined;
  }
}

export default function ApplicationPage() {
  const { viewerContext } = useUserContextV2();
  const viewerType = viewerContext.getViewerType();

  switch (viewerType) {
    case CapitalizedUserTypes.LENDER:
      return <ApplicationLenderPageContent />;
    case CapitalizedUserTypes.BORROWER:
      return <ApplicationBorrowerPageContent />;
    case CapitalizedUserTypes.PARTNER:
      return <ApplicationPartnerPageContent />;
    default:
      return null;
  }
}
