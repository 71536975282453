import { useEffect, useState } from "react";

/**
 * When using this hook, make sure you are handling excessive rerenders
 * as this hook _will_ cause rerenders frequently as the user resizes the window.
 */
export default function useWindowResize() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function resize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return width;
}
