import { RouteObject } from "react-router-dom";
import ViewerContext from "context/UserContextV2/ViewerContext";
import BaseRouteController from "routes/BaseRouteController";
import ResetPasswordConfirmPageRouteController from "routes/ResetPasswordPage/ResetPasswordConfirmPageRouteController";
import ResetPasswordPage from ".";

export default class ResetPasswordPageRouteController extends BaseRouteController {
  public getRouteConfig(): RouteObject {
    return {
      element: this.getElement(),
      errorElement: this.getErrorElement(),
      index: true,
      loader: this.getLoader(),
      path: this.getPath(),
    };
  }

  public static override getRoutePath(): string | undefined {
    return undefined;
  }

  public getPath(): string | undefined {
    return "/reset-password";
  }

  public async genCanUserView(): Promise<boolean> {
    return true;
  }

  public getErrorElement(): JSX.Element | undefined {
    return undefined;
  }

  public getElementImpl(): JSX.Element | undefined {
    return <ResetPasswordPage />;
  }

  public getChildrenRouteControllers(): (new (
    viewerContext: ViewerContext
  ) => BaseRouteController)[] {
    return [ResetPasswordConfirmPageRouteController];
  }
}
