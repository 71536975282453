import { ApiBusinessFundingSource } from "@presta-technologies-inc/presta-types";
import joinPaths from "routes/AppRoutes/joinPaths";

export type ApiGet_BusinessFundingSourcesByBusinessIdResponse =
  Array<ApiBusinessFundingSource>;

/**
 * @param businessId ID
 * @returns business-funding-source/business/{businessId}
 */
export default function getBusinessFundingSourcesByBusinessIdEndpoint(
  businessId: ID
) {
  return joinPaths("business-funding-source", "business", businessId);
}
