import { User } from "./login";

export type ApiPost_AuthRegisterResponse = {
  knockToken: string;
  refreshToken: string;
  token: string;
  user: User;
};

/**
 * @returns "auth/register"
 */
export default function getAuthRegisterEndpoint() {
  return "auth/register";
}
