/* eslint-disable react/function-component-definition */
import { Table as MuiTable, TableProps } from "@mui/material";

/**
 * An abstraction around the MuiTable component setting the component as a 'div'.
 *
 * Why not override via `defaultProps` in the ThemeProvider?
 * It turns out because MUI's Table has overridable props, only some props
 * are exposed to be overridden, and `component` is not one of them for ALL
 * the table elements.
 */
const Table: typeof MuiTable = (props: TableProps): JSX.Element => {
  return <MuiTable component="div" {...props} />;
};

export default Table;
