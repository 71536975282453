import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import client from "utilities/apiClient";
import { InputType } from "types/InputType";
import MobileResponsiveContainer from "components/MobileResponsiveContainer";
import useMutation from "hooks/useMutation";
import getAuthLoginEndpoint, {
  ApiPost_AuthLoginResponse,
} from "api/endpoints/auth/login";
import { usePrestaCookies } from "context/PrestaCookiesContext";
import { useUserContextV2 } from "context/UserContextV2";
import ViewerContext from "context/UserContextV2/ViewerContext";
import EmailVerificationPageRouteController from "routes/EmailVerificationPage/EmailVerificationPageRouteController";
import useBoolean from "hooks/useBoolean";
import { Statsig } from "statsig-react";
import StatsigEvents from "utilities/statsig/StatsigEvents";
import isEmailVerificationGKEnabled from "utilities/gatekeeper/gkChecks/isEmailVerificationGKEnabled";
import LoginDesktopPage from "./desktop/LoginDesktopPage";
import LoginMobilePage from "./mobile/LoginMobilePage";

const ERROR_MESSAGE = "Either your email or password is incorrect";

export default function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState<boolean | null>(null);
  const [passwordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { lenderId } = useParams();
  const [lenderThumbnailURL, setLenderThumbnailURL] = useState("");
  const { setViewerContext } = useUserContextV2();
  const { isTrue: isInFlight, toggle: toggleIsInFlight } = useBoolean(false);

  const { setCookies } = usePrestaCookies();

  const [commitPost] = useMutation();
  function handleLogin({
    password: passwordImpl,
    username,
  }: {
    password: string;
    username: string;
  }) {
    const body = { password: passwordImpl, username };
    commitPost<ApiPost_AuthLoginResponse, typeof body>({
      body,
      endpoint: getAuthLoginEndpoint(),
      onCompleted(response) {
        if (response.status === "success") {
          const { data } = response;
          const { knockToken, refreshToken, token, user } = data;
          const {
            didAcceptTermsOfServiceAndPrivacyPolicy:
              _didAcceptTermsOfServiceAndPrivacyPolicy,
            isEmailVerified,
          } = user;
          setCookies([
            { name: "token", value: token },
            { name: "refreshToken", value: refreshToken },
            { name: "user", value: user },
            { name: "knockToken", value: knockToken },
          ]);
          setViewerContext(new ViewerContext(user));
          setTimeout(() => {
            if (!isEmailVerified && isEmailVerificationGKEnabled()) {
              navigate(EmailVerificationPageRouteController.buildRoutePath());
            }
            // Adding this within a setTimeout so that the viewerContext is set before actually logging to Statsig.
            // This way, the userId is defined
            setTimeout(() => {
              const { code, firstName, lastName, orgName, orgType } = user;
              Statsig.logEvent(StatsigEvents.LOGIN, code, {
                code,
                email: user.email,
                firstName,
                lastName,
                orgName,
                orgType,
              });
            }, 500);
          }, 0);
        } else {
          setEmailError(true);
          setPasswordError(true);
          setErrorMessage(ERROR_MESSAGE);
          toggleIsInFlight();
        }
      },
      onError() {
        setEmailError(true);
        setPasswordError(true);
        setErrorMessage(ERROR_MESSAGE);
        toggleIsInFlight();
      },
    });
  }

  useEffect(() => {
    if (lenderId != null) {
      // TODO - convert to useGet or route loader
      const fetchLenderLogo = async () => {
        // Make a request to get the lender's logo URL from the backend
        const response = await client(`lender/${lenderId}`);

        // Update the URL state variable so it gets displayed at the top of the login page
        const { thumbnail } = response.data;
        setLenderThumbnailURL(thumbnail);
      };
      fetchLenderLogo();
    }
  }, [lenderId]);

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    // Validate the email and password fields
    const emailIsValid = email !== "";
    const passwordIsValid = password !== "";

    // Update the email and password error states
    setEmailError(!emailIsValid);
    setPasswordError(!passwordIsValid);

    // If the email and password are valid, make a POST auth/login request
    // with the email and password fields
    if (emailIsValid && passwordIsValid) {
      // Clear the errorMessage in preparation for a new request
      setErrorMessage("");

      // Make a login request
      // - If the credentials were good, redirect them to the /document screen
      // - Otherwise, set the error message to inform the user something went wrong
      handleLogin({
        password,
        username: email,
      });
    }
  };

  const handleChange = (input: string, inputType: InputType) => {
    // Reset both error states when input is updated
    setEmailError(false);
    setPasswordError(false);
    setErrorMessage("");
    switch (inputType) {
      case InputType.EMAIL:
        setEmail(input);
        break;
      case InputType.PASSWORD:
        setPassword(input);
        break;
      default:
        break;
    }
  };

  const props = {
    email,
    emailError,
    errorMessage,
    handleChange,
    handleSubmit,
    isInFlight,
    lenderThumbnailURL,
    password,
    passwordError,
    setPassword,
    toggleIsInFlight,
  };

  return (
    <MobileResponsiveContainer
      desktop={<LoginDesktopPage {...props} />}
      mobile={<LoginMobilePage {...props} />}
    />
  );
}
