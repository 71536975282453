import * as React from "react";
import { Statsig } from "statsig-react";
import StatsigEvents from "utilities/statsig/StatsigEvents";

type Props = {
  children: JSX.Element | Array<JSX.Element>;
  componentName: string;
  fallback: JSX.Element | null;
};

type State = {
  hasError: boolean;
};

/**
 * Use this error boundary to wrap around individual components.
 * This is ideal for the "root" components so that only this will fail
 * rather than the entire application.
 */
export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public override componentDidCatch(
    error: Error,
    errorInfo: React.ErrorInfo
  ): void {
    const { componentName } = this.props;

    // eslint-disable-next-line no-console
    console.error("Error: ", error);
    // eslint-disable-next-line no-console
    console.error("Error Info: ", errorInfo);

    try {
      Statsig.logEvent(StatsigEvents.APP_ERROR, error.message, {
        component: componentName,
        componentStack: errorInfo.componentStack ?? "",
        pathname: window.location.pathname,
      });
    } catch (e) {
      // do nothing - statsig was the problem
    }

    this.setState({
      hasError: true,
    });
  }

  public override render() {
    const { hasError } = this.state;
    const { children, fallback } = this.props;

    if (hasError) {
      if (fallback === undefined) {
        return null;
      }
      return fallback;
    }

    return children;
  }
}
