enum UserTypes {
  BORROWER = "borrower",
  LENDER = "lender",
  PARTNER = "partner",
}

export enum CapitalizedUserTypes {
  BORROWER = "Borrower",
  LENDER = "Lender",
  PARTNER = "Partner",
}

export default UserTypes;
