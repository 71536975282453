import { useEffect, useState } from "react";
import {
  Autocomplete,
  MenuItem,
  SxProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";
import setUrlSearchParams from "utilities/setUrlSearchParams";
import encryptToString from "utilities/encryption/encryptToString";
import decryptToString from "utilities/encryption/decryptToString";
import deleteUrlSearchParams from "utilities/deleteUrlSearchParams";
import {
  PowerSearchOptionsMap,
  PowerSearchFilterData,
} from "./PowerSearchTypes";
import {
  powerSearchFilterSelector,
  selectedPowerSearchValuesAtom,
} from "./PowerSearchRecoil";
import PowerSearchTag from "./PowerSearchTag";

type Props = {
  /**
   * IMPORTANT make sure to wrap this method with a useCallback
   * to prevent excessive rerendering
   */
  handleFilter(filterData: PowerSearchFilterData | null): void;
  optionsMap: PowerSearchOptionsMap;
  saveToQueryParams?: boolean;
  sx?: SxProps;
  textFieldProps?: TextFieldProps;
};

function PowerSearchImpl({
  handleFilter,
  optionsMap,
  saveToQueryParams = false,
  sx,
  textFieldProps = {},
}: Props) {
  const [powerSearchFilterData, setPowerSearchFilterData] =
    useState<PowerSearchFilterData | null>(null);
  const [selectedValues, setSelectedValues] = useRecoilState(
    selectedPowerSearchValuesAtom
  );

  useEffect(() => {
    if (saveToQueryParams) {
      const searchParams = new URLSearchParams(window.location.search);

      const queryParam = searchParams.get("query") ?? null;

      if (queryParam == null) {
        return;
      }

      const decrypted = decryptToString(queryParam);
      try {
        const filterData = JSON.parse(decrypted);
        const values = Object.keys(filterData);
        // convert arrays to Sets
        const out = values.reduce<PowerSearchFilterData>((accum, value) => {
          return {
            ...accum,
            [value]: {
              ...accum[value],
              is: new Set(filterData[value]?.is ?? []),
              isNot: new Set(filterData[value]?.isNot ?? []),
            },
          };
        }, {});
        setPowerSearchFilterData(out);
        handleFilter(out);
        setSelectedValues(values);
      } catch (err) {
        // do nothing
      }
    }
  }, [handleFilter, saveToQueryParams, setSelectedValues]);

  const getFilterData = useRecoilValue(powerSearchFilterSelector);

  function setToQueryParams(filterData: PowerSearchFilterData | null): void {
    if (filterData == null) {
      deleteUrlSearchParams(["query"]);
      return;
    }
    // convert sets to arrays
    const data = Object.keys(filterData).reduce<{
      [key: string]: {
        is: Array<string>;
        isNot: Array<string>;
      };
    }>((accum, key) => {
      return {
        ...accum,
        [key]: {
          ...accum[key],
          is: Array.from(filterData[key]?.is ?? new Set()),
          isNot: Array.from(filterData[key]?.isNot ?? new Set()),
        },
      };
    }, {});
    const query = encryptToString(JSON.stringify(data));
    setUrlSearchParams({
      query,
    });
  }

  return (
    <Autocomplete
      multiple
      onBlur={() => {
        const filterData = getFilterData();
        handleFilter(filterData);
        setToQueryParams(filterData);
      }}
      onChange={(_event, newValue) => {
        setSelectedValues(newValue);
      }}
      options={Object.keys(optionsMap)}
      renderInput={(inputProps) => {
        return (
          <TextField
            autoComplete="off"
            {...inputProps}
            placeholder="Filter..."
            variant="outlined"
            {...textFieldProps}
            sx={{
              ...(textFieldProps.sx ?? {}),
              background: (theme) => theme.palette.background.paper,
            }}
          />
        );
      }}
      renderOption={(props, option) => {
        return <MenuItem {...props}>{optionsMap[option].label}</MenuItem>;
      }}
      renderTags={(values, getTagProps) => {
        return values.map((value, index) => {
          const optionsForValue = optionsMap[value as keyof typeof optionsMap];
          return (
            <PowerSearchTag
              key={`${value}-${index}`}
              initialOptions={powerSearchFilterData?.[value] ?? null}
              options={optionsForValue}
              tagProps={getTagProps({ index })}
              value={value}
            />
          );
        });
      }}
      sx={sx}
      value={selectedValues}
    />
  );
}

export default function PowerSearch(props: Props) {
  return (
    <RecoilRoot>
      <PowerSearchImpl {...props} />
    </RecoilRoot>
  );
}
