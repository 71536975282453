import { Skeleton as MuiSkeleton, SkeletonProps } from "@mui/material";

export default function Skeleton({ sx, ...rest }: SkeletonProps) {
  return (
    <MuiSkeleton
      animation="wave"
      height={30}
      sx={{
        ...sx,
      }}
      variant="text"
      {...rest}
    />
  );
}
