import * as React from "react";
import { createContext, useContext, useMemo, useReducer } from "react";
import { Button, SnackbarProps } from "@mui/material";
import SnackbarItem from "components/general/SnackbarItem";

enum SnackbarActions {
  CLOSE = "CLOSE",
  OPEN = "OPEN",
}
type Props = {
  children: React.ReactNode;
};
type ActionButton = {
  label: string;
  onClick: () => void;
};
type Snackbar = {
  action?: JSX.Element | null;
  anchorOrigin: SnackbarProps["anchorOrigin"];
  autoHideDuration: number;
  message?: string | JSX.Element;
  open: boolean;
};
type Action =
  | { type: SnackbarActions.CLOSE }
  | {
      payload: {
        action?: ActionButton | null;
        message?: string | JSX.Element;
      };
      type: SnackbarActions.OPEN;
    };
type Context = {
  closeSnackbar(): void;
  openSnackbar(
    message?: string | JSX.Element,
    action?: ActionButton | null
  ): void;
  snackbarState: Snackbar;
};
const initialState: Snackbar = {
  action: null,
  anchorOrigin: { horizontal: "center", vertical: "bottom" },
  autoHideDuration: 2000,
  message: "",
  open: false,
};
function getSnackbarButton(buttonActions: ActionButton) {
  const { label, onClick } = buttonActions;
  return (
    <Button
      color="inherit"
      data-testid="snackbar-button"
      onClick={onClick}
      size="medium"
      sx={(theme) => ({
        fontSize: theme.typography.pxToRem(16),
        fontWeight: "500",
      })}
    >
      {label}
    </Button>
  );
}
function reducer(state: Snackbar, action: Action): Snackbar {
  switch (action.type) {
    case SnackbarActions.OPEN:
      return {
        ...state,
        action: action.payload.action
          ? getSnackbarButton?.(action.payload.action)
          : null,
        message: action.payload.message,
        open: true,
      };
    case SnackbarActions.CLOSE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export const SnackbarContext = createContext<Context | null>(null);

export function SnackbarContextProvider(props: Props) {
  const { children } = props;
  const [snackbarState, dispatch] = useReducer(reducer, initialState);

  function openSnackbar(
    message: string | JSX.Element,
    action: ActionButton | null = null
  ) {
    dispatch({
      payload: { action, message },
      type: SnackbarActions.OPEN,
    });
  }

  function closeSnackbar() {
    dispatch({
      type: SnackbarActions.CLOSE,
    });
  }

  const snackbarContext = useMemo(
    () => ({
      closeSnackbar,
      openSnackbar,
      snackbarState,
    }),
    [snackbarState]
  );

  return (
    <SnackbarContext.Provider value={snackbarContext}>
      <SnackbarItem />
      {children}
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  const context = useContext(SnackbarContext);
  if (context == null) {
    throw new Error("Snackbar context must be used within its provider");
  }
  return context;
}
