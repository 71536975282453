export type ApiGet_PasswordResetValid = {
  isValid: boolean;
};

/**
 * @param code string
 * @returns account/check-reset-password?code={code}
 */
export default function getPasswordResetValidEndpoint(code: string) {
  return `account/check-reset-password?code=${code}`;
}
