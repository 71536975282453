import { LoaderFunction, RouteObject, To } from "react-router-dom";
import ViewerContext from "context/UserContextV2/ViewerContext";
import BaseRouteController from "routes/BaseRouteController";
import client from "utilities/apiClient";
import getPasswordResetValidEndpoint, {
  ApiGet_PasswordResetValid,
} from "api/endpoints/get/password/getPasswordResetValidEndpoint";
import getNavigateConfig from "utilities/getNavigateConfig";
import getQuerySearchParams from "utilities/getQuerySearchParams";
import ResetPasswordConfirmPage from "./ResetPasswordConfirmPage";

export default class ResetPasswordConfirmPageRouteController extends BaseRouteController {
  public getRouteConfig(): RouteObject {
    return {
      children: this.getChildren(),
      element: this.getElement(),
      errorElement: this.getErrorElement(),
      loader: this.getLoader(),
      path: this.getPath(),
    };
  }

  public override loader: LoaderFunction = async () => {
    const { code } = getQuerySearchParams();
    if (code != null) {
      const response = await client<ApiPayload<ApiGet_PasswordResetValid>>(
        getPasswordResetValidEndpoint(String(code))
      );
      return {
        passwordData: response.data,
      };
    }
    return undefined;
  };

  /**
   * /reset-password/confirm
   */
  public static override getRoutePath(): string {
    return "/reset-password/confirm";
  }

  public static override buildRoutePath(): To {
    return getNavigateConfig(
      ResetPasswordConfirmPageRouteController.getRoutePath()
    );
  }

  public getPath(): string | undefined {
    return "confirm";
  }

  public async genCanUserView(): Promise<boolean> {
    return true;
  }

  public getErrorElement(): JSX.Element | undefined {
    return undefined;
  }

  public getElementImpl(): JSX.Element | undefined {
    return <ResetPasswordConfirmPage />;
  }

  public getChildrenRouteControllers(): (new (
    viewerContext: ViewerContext
  ) => BaseRouteController)[] {
    return [];
  }
}
