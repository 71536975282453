import { User } from "api/endpoints/auth/login";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import CookieUtils from "utilities/CookieUtils";

export type CookieTypes = "token" | "refreshToken" | "user" | "knockToken";

export type Cookies = {
  knockToken: string;
  refreshToken: string;
  token: string;
  user: User | null;
};

type PrestaCookiesContextType = {
  cookies: Cookies;
  setCookie(
    name: string,
    value: unknown,
    config?: {
      maxAge: number;
      path: string;
    }
  ): void;
  setCookies(
    newCookies: Array<{
      config?: { maxAge: number; path: string };
      name: string;
      value: unknown;
    }>
  ): void;
};

export const PrestaCookiesContext = createContext<
  PrestaCookiesContextType | undefined
>(undefined);

export function usePrestaCookies() {
  const context = useContext(PrestaCookiesContext);
  if (context == null) {
    throw new Error("PrestaCookiesContext must be used within its provider");
  }
  return context;
}

export function PrestaCookiesProvider({ children }: { children: JSX.Element }) {
  const [cookies, setCookiesImpl] = useState<Cookies>(() =>
    CookieUtils.getAllCookies<Cookies>()
  );

  const setCookie = useCallback(function setCookie(
    name: string,
    value: unknown,
    config?: { maxAge: number; path: string }
  ) {
    CookieUtils.setCookie(name, value, config);
    setCookiesImpl(CookieUtils.getAllCookies<Cookies>());
  },
  []);

  const setCookies = useCallback(function setCookies(
    newCookies: Array<{
      config?: { maxAge: number; path: string };
      name: string;
      value: unknown;
    }>
  ) {
    for (const { config, name, value } of newCookies) {
      CookieUtils.setCookie(name, value, config);
    }
    setCookiesImpl(CookieUtils.getAllCookies<Cookies>());
  }, []);

  const memoizedValue = useMemo(
    () => ({ cookies, setCookie, setCookies }),
    [cookies, setCookie, setCookies]
  );

  return (
    <PrestaCookiesContext.Provider value={memoizedValue}>
      {children}
    </PrestaCookiesContext.Provider>
  );
}
