import { Fade } from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import Colors from "components/styles/Colors";
import { useUserContextV2 } from "context/UserContextV2";

// For Branding
// https://www.figma.com/file/QgfnTj0tVaMmBK8TRRLGts/Happy-Path---Nov-28?node-id=9%3A2860&t=qzxfX12g2PaS2qc2-0
// https://www.notion.so/letspresta/Color-Accessibility-7e56fb8a4f8e4c0484b640b443561789

// https://mui.com/material-ui/customization/theming/

function getThemeVal(isBorrower: boolean) {
  return responsiveFontSizes(
    createTheme({
      components: {
        // Accordion
        MuiAccordion: {
          defaultProps: {
            elevation: 0,
          },
        },

        // Buttons
        MuiButton: {
          defaultProps: {
            size: "small",
          },
          styleOverrides: {
            root: ({ theme }) => ({
              "&.MuiButton-contained.Mui-disabled": {
                color: theme.palette.primary.contrastText,
              },
              borderRadius: "4px",
            }),
          },
        },
        MuiButtonBase: {
          styleOverrides: {
            root: {
              fontFamily: "inherit",
            },
          },
        },

        // Dialog
        MuiDialogContent: {
          defaultProps: {
            dividers: true,
          },
        },

        MuiIconButton: {
          defaultProps: {
            size: "small",
          },
        },

        MuiInput: {
          defaultProps: {
            size: "small",
          },
        },

        // List
        MuiListItemButton: {
          defaultProps: {
            dense: true,
          },
        },

        MuiListItemText: {
          defaultProps: {
            disableTypography: true,
          },
        },

        // Menu
        MuiMenu: {
          defaultProps: {
            TransitionComponent: Fade,
          },
        },

        // Inputs
        MuiOutlinedInput: {
          defaultProps: {
            size: "small",
          },
          styleOverrides: {
            root: ({ theme }) => ({
              borderColor: theme.palette.primary.main,
            }),
          },
        },

        MuiTable: {
          styleOverrides: {
            root: ({ theme }) => ({
              borderCollapse: "separate",
              borderSpacing: theme.spacing(0, 1),
            }),
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: ({ theme }) => ({
              borderBottom: "none",
              padding: theme.spacing(1.2, 1.2, 1.2, 2),
            }),
          },
        },
        MuiTableContainer: {
          styleOverrides: {
            root: {
              width: "unset",
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: ({ theme }) => ({
              backgroundColor: theme.palette.background.paper,
            }),
          },
        },
        MuiTextField: {
          defaultProps: {
            size: "small",
          },
          styleOverrides: {
            root: ({ theme }) => ({
              "& .MuiOutlinedInput-root:hover": {
                "& > fieldset": {
                  borderColor: theme.palette.primary.light,
                },
              },
              fieldset: {
                borderColor: theme.palette.primary.main,
              },
            }),
          },
        },
        // Tooltip
        MuiTooltip: {
          styleOverrides: {
            arrow: ({ theme }) => ({ color: theme.palette.grey[800] }),
            tooltip: ({ theme }) => ({
              backgroundColor: theme.palette.grey[800],
            }),
          },
        },
      },
      palette: {
        background: {
          default: Colors.backgroundGrey,
        },
        divider: Colors.lineGreyMedium,
        error: {
          main: Colors.error,
        },
        info: {
          light: Colors.lightTeal,
          main: Colors.teal,
        },
        primary: {
          main: isBorrower ? Colors.darkPurple : Colors.darkTeal,
        },
        success: {
          main: Colors.success,
        },
      },
      typography: {
        fontFamily: [
          "Space Grotesk",
          "-apple-system",
          "BlinkMacSystemFont",
          "Segoe UI",
          "Roboto",
          "Oxygen",
          "Ubuntu",
          "Cantarell",
          "Fira Sans",
          "Droid Sans",
          "Helvetica Neue",
          "sans-serif",
        ].join(","),
      },
    })
  );
}

export default function AppThemeProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const { viewerContext } = useUserContextV2();
  const user = viewerContext.getUser();

  const theme = getThemeVal(user?.isBorrower ?? false);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
