import { CookieTypes } from "context/PrestaCookiesContext";

export default class CookieUtils {
  public static EXPIRE_DATE = "expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";

  private static getCookiesList() {
    return document.cookie.split(";");
  }

  public static setCookie(
    name: string,
    value: unknown,
    config?: {
      maxAge?: number;
      path?: string;
    }
  ) {
    if (String(value).length === 0 || value == null) {
      CookieUtils.deleteCookie(name);
      return;
    }
    const { maxAge = 43200, path = "/" } = config ?? {};
    const stringifiedValue =
      typeof value === "object" ? JSON.stringify(value) : value;
    document.cookie = `${name}=${stringifiedValue}; max-age=${maxAge}; path=${path}`;
  }

  public static getCookie<T>(name: CookieTypes | string): T | string {
    const cookies = CookieUtils.getCookiesList();
    for (const cookie of cookies) {
      const [key, value] = cookie.split("=");
      const trimmedKey = key.trim();
      if (name === trimmedKey) {
        let out: T | string = value;
        try {
          out = JSON.parse(value);
        } catch (e) {
          // do nothing
        }
        return out;
      }
    }
    return "";
  }

  public static getAllCookies<T extends Object>() {
    const cookies = CookieUtils.getCookiesList();
    return cookies.reduce<T>((accum, cookie) => {
      const trimmedCookie = cookie.trim();
      const [key, value] = trimmedCookie.split("=");
      let parsed = value;
      try {
        parsed = JSON.parse(value);
      } catch (e) {
        // do nothing
      }
      return {
        ...accum,
        [key]: parsed,
      };
    }, {} as T);
  }

  public static deleteCookie(name: string) {
    document.cookie = `${name}=; ${CookieUtils.EXPIRE_DATE}`;
  }

  public static deleteAllCookies() {
    const cookies = CookieUtils.getCookiesList();
    for (const cookie of cookies) {
      const [cookieKey] = cookie.split("=");
      const cookieName = cookieKey.trim();
      document.cookie = `${cookieName}=; ${CookieUtils.EXPIRE_DATE}`;
    }
  }
}
