import * as React from "react";
import MobileResponsiveContainer from "components/MobileResponsiveContainer";
import useLoaderData from "hooks/useLoaderData";
import { ApplicationExportPageLoaderData } from "routes/ApplicationsPage/ApplicationsExportPage/ApplicationsExportPageRouteController";
import { ApiGet_ExportRequest } from "api/endpoints/get/export/getExportRequestsEndpoint";
import { ApiGet_ApplicationType } from "api/endpoints/get/application/getApplicationEndpoint";
import useGetWithLoader from "hooks/useGetWithLoader";
import postExportRequestEndpoint from "api/endpoints/post/export/getPostExportRequestEndpoint";

const ApplicationsExportPageDesktop = React.lazy(
  () => import("./ApplicationsExportPageDesktop")
);
const ApplicationsExportPageMobile = React.lazy(
  () => import("./mobile/ApplicationsExportPageMobile")
);

export type EnrichedApplicationExportRequest = ApiGet_ExportRequest & {
  previouslyExportedApplications: string;
};

export type ApplicationsExportPageProps = {
  applicationList: ApiGet_ApplicationType[];
  enrichedApplicationExportRequests: EnrichedApplicationExportRequest[];
  getSnackbarMessage: (row: EnrichedApplicationExportRequest) => string;
  refetchExportRequests: () => Promise<ApiGet_ExportRequest[]>;
  selectedExportId?: ID;
};

function getApplicationName(exportRequest: ApiGet_ExportRequest) {
  if (exportRequest.applicationIds.length > 1) {
    return `${exportRequest.applicationIds.length} Applications`;
  }
  return `${exportRequest.applications[0].borrowerOrganizationName}`;
}

export default function ApplicationsExportPage() {
  const {
    applicationList,
    exportRequests: exportRequestsInit,
    selectedExportId,
  } = useLoaderData<ApplicationExportPageLoaderData>();

  const { data: exportRequests, refetch: refetchExportRequests } =
    useGetWithLoader(postExportRequestEndpoint(), exportRequestsInit);

  const enrichedApplicationExportRequests = exportRequests.map(
    (exportRequest: ApiGet_ExportRequest) => {
      return {
        ...exportRequest,
        previouslyExportedApplications: getApplicationName(exportRequest),
      };
    }
  );

  const getSnackbarMessage = (row: EnrichedApplicationExportRequest) => {
    if (row.applicationIds.length > 1) {
      return `You downloaded ${row.applicationIds.length} Applications`;
    }
    return `You downloaded ${row.applications[0].borrowerOrganizationName}'s Application`;
  };

  const contentProps: ApplicationsExportPageProps = {
    applicationList,
    enrichedApplicationExportRequests,
    getSnackbarMessage,
    refetchExportRequests,
    selectedExportId,
  };

  return (
    <MobileResponsiveContainer
      desktop={<ApplicationsExportPageDesktop {...contentProps} />}
      mobile={<ApplicationsExportPageMobile {...contentProps} />}
    />
  );
}
