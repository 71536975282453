import ViewerContext from "context/UserContextV2/ViewerContext";
import {
  IndexRouteObject,
  LoaderFunction,
  RouteObject,
  To,
  redirect,
} from "react-router-dom";
import CookieUtils from "utilities/CookieUtils";
import getNavigateConfig from "utilities/getNavigateConfig";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";
import StatsigPageLog from "utilities/statsig/StatsigPageLog";
import LoginRouteController from "./LoginPage/LoginRouteController";
import PageNotFoundPage from "./PageNotFoundPage";

interface IBaseRouteController {
  /**
   * Async method to determine if a user can view a route
   */
  genCanUserView?: () => Promise<boolean>;
  /**
   * Abstracted method for the route loader to handle if
   * the user can view that route. If not, they are redirected to LOGIN
   */
  genLoader?: LoaderFunction | undefined;
  /**
   * Returns the build route config array for the children
   */
  getChildren(): Array<RouteObject> | undefined;
  /**
   * A list of route controllers that are the child routes of the current route
   */
  getChildrenRouteControllers(): Array<
    // eslint-disable-next-line no-use-before-define
    new (viewerContext: ViewerContext) => BaseRouteController
  >;
  /**
   * JSX element returned for the route config
   */
  getElement(): JSX.Element | null | undefined;
  /**
   * Implementation for getElement
   */
  getElementImpl(): JSX.Element | null | undefined;
  /**
   * JSX element for the error state for the route config
   */
  getErrorElement(): JSX.Element | undefined | null;
  /**
   * returns boolean if the pathname matches the current route's path.
   * `pathname` can be accessed via window.location or a route loader's param
   */
  getIsCurrentPath(pathname: string): boolean;
  /**
   * Returns the completed loader(genLoader).
   * Use this method to pass to the route config
   */
  getLoader(): LoaderFunction | undefined;
  /**
   * The path string for the particular route
   */
  getPath(): string | undefined;
  /**
   * The Javascript config object to be read by React Router
   */
  getRouteConfig(): RouteObject;
  /**
   * The loader method that is called upon navigation to a route.
   * Use to fetch route data and check user permissions
   */
  loader?: IndexRouteObject["loader"];
  /**
   * The current viewer who is viewing the app
   */
  viewerContext: ViewerContext;
}

export default abstract class BaseRouteController
  implements IBaseRouteController
{
  constructor(public viewerContext: ViewerContext) {}

  /**
   * This is the path to use to route. If you need to have the navigation config,
   * use `buildRoutePath`
   */
  public static getRoutePath(
    ..._args: Array<string | ID | Partial<Location> | undefined>
  ): string | undefined {
    return undefined;
  }

  /**
   * Returns the full path with the existing url query params
   */
  public static buildRoutePath(
    ..._args: Array<
      | string
      | ID
      | Partial<Location>
      | undefined
      | Parameters<typeof getNavigateConfig>[1]
    >
  ): To | undefined {
    return undefined;
  }

  public getViewerContext() {
    return this.viewerContext;
  }

  /**
   * Syntactic sugar for getViewerContext
   */
  public getVC() {
    return this.viewerContext;
  }

  public loader: LoaderFunction | undefined = undefined;

  public genLoader: LoaderFunction = async (args) => {
    try {
      if (await this.genCanUserView()) {
        return (await this.loader?.(args)) ?? null;
      }
      throw new Error("User cannot view this route");
    } catch (e) {
      const vc = this.getVC();
      const doesUserExist =
        vc.getUser() != null && Boolean(CookieUtils.getCookie("token"));
      if (doesUserExist) {
        return redirect("/404");
      }
      return redirect(this.addNextRoute(LoginRouteController.getRoutePath()));
    }
  };

  private addNextRoute(basePath: string) {
    if (window.location.pathname !== LoginRouteController.getRoutePath()) {
      const fullPath = window.location.pathname + window.location.search;
      const search = new URLSearchParams({
        [LoginRouteController.QUERY_PARAM]: fullPath,
      });
      return `${basePath}/?${search.toString()}`;
    }
    return basePath;
  }

  public getLoader(): undefined | LoaderFunction {
    return this.genLoader;
  }

  public getElement(): JSX.Element | null | undefined {
    const element = this.getElementImpl();
    if (element == null) {
      return null;
    }
    return (
      <ErrorBoundary
        componentName={this.constructor.name}
        fallback={this.getErrorElement() ?? <PageNotFoundPage />}
      >
        <StatsigPageLog>{element}</StatsigPageLog>
      </ErrorBoundary>
    );
  }

  public static getIsCurrentPath(
    pathname: string,
    currentPath: string
  ): boolean {
    const array = pathname.split("/").filter(Boolean);
    return array.pop() === currentPath;
  }

  /**
   * @param pathname usually can be access via window.location or a loader's request
   * @returns boolean
   */
  public getIsCurrentPath(pathname: string): boolean {
    const currentPath = this.getPath();
    if (currentPath == null) {
      return false;
    }
    return BaseRouteController.getIsCurrentPath(pathname, currentPath);
  }

  public abstract getRouteConfig(): RouteObject;

  public abstract getPath(): string | undefined;

  public abstract genCanUserView(): Promise<boolean>;

  public abstract getErrorElement(): JSX.Element | undefined | null;

  public abstract getElementImpl(): JSX.Element | null | undefined;

  public abstract getChildrenRouteControllers(): Array<
    new (viewerContext: ViewerContext) => BaseRouteController
  >;

  public getChildren(): Array<RouteObject> | undefined {
    const routeControllers = this.getChildrenRouteControllers();
    const vc = this.getVC();
    return routeControllers.map((Controller) => {
      const instance = new Controller(vc);
      return instance.getRouteConfig();
    });
  }
}
