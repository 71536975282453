const Colors = {
  backgroundGrey: "#F3F4F8",
  backgroundGreyHover: "#E7ECF1",
  closeIconGrey: "#C4C4C4",
  darkGrey: "#30363D",
  darkPurple: "#656FC7",
  darkTeal: "#39818E",
  darkTealDisabled: "#9CC0C6",
  darkTealHover: "#2A5F69",
  error: "#EC5B30",
  focusBlue: "#0D76A4",
  green: "#1DAA5D",
  lightGreen: "#ECF5F0",
  lightGrey: "#686C73",
  lightOrange: "#FAF0ED",
  lightTeal: "#F0FBFC",
  lineGreyDark: "#879EA8",
  lineGreyLight: "#DAE1E8",
  lineGreyMedium: "#C8DAE2",
  manilla: "#FFFCF4",
  orange: "#EC5B30",
  pink: "#FFC9D7",
  purple: "#717DDC",
  success: "#1DAA5D",
  teal: "#4AA3B3",
  tealDisabled: "#A4D1D9",
  tealHover: "#3A808C",
  white: "#FFFFFF",
  whiteHover: "#DBEDF0",
  yellow: "#F6C543",
};

export default Colors;
