/**
 * Removes the given params from the url query params.
 *
 * This is contrary to setting the value to an empty string.
 *
 * ```example
 * /?foo=foo
 * /?foo=
 *
 * VS
 *
 * /?foo=foo
 * /?
 * ```
 */
export default function deleteUrlSearchParams(params: Array<string>) {
  const newSearchParams = new URLSearchParams(window.location.search);
  for (const param of params) {
    newSearchParams.delete(param);
  }
  const newSearch = `?${newSearchParams.toString()}`;
  const { pathname } = window.location;
  window.history.pushState({}, "", `${pathname}${newSearch}`);
}
