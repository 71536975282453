import * as React from "react";
import { useMemo, useCallback, useState } from "react";
import getApplicationEndpoint, {
  ApiGet_PartnerApplicationType,
} from "api/endpoints/get/application/getApplicationEndpoint";
import useLoaderData from "hooks/useLoaderData";
import useGetWithLoader from "hooks/useGetWithLoader";
import buildLoaderFunction from "utilities/buildLoaderFunction";
import client from "utilities/apiClient";
import MobileResponsiveContainer from "components/MobileResponsiveContainer";
import {
  PowerSearchFilterData,
  PowerSearchOptionsMap,
} from "components/PowerSearch/PowerSearchTypes";
import powerSearchFilterItems from "components/PowerSearch/powerSearchFilterItems";

const ApplicationPartnerPageContentDesktop = React.lazy(
  () => import("./ApplicationPartnerPageContentDesktop")
);

const ApplicationPartnerPageContentMobile = React.lazy(
  () => import("./mobile/ApplicationPartnerPageContentMobile")
);

export type ApplicationPartnerPageProps = {
  handleFilter(filterData: PowerSearchFilterData | null): void;
  items: Array<{
    id: ID;
    leadPartner: string;
    loanProductName: string;
    menu: null;
    orgName: string;
    stepName: string;
  }>;
  itemsLength: number;
  optionsMap: PowerSearchOptionsMap;
  powerSearchFilterData: PowerSearchFilterData | null;
  refetchApplicationList: () => Promise<Array<ApiGet_PartnerApplicationType>>;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
};

export type ApplicationPageLoaderData = {
  applicationList: Array<ApiGet_PartnerApplicationType>;
};

export const applicationPartnerPageLoader =
  buildLoaderFunction<ApplicationPageLoaderData>(async () => {
    const { data } = await client<
      ApiPayload<Array<ApiGet_PartnerApplicationType>>
    >(getApplicationEndpoint());

    return { applicationList: data };
  });

export default function ApplicationPartnerPageContent() {
  const [searchTerm, setSearchTerm] = useState("");
  const [powerSearchFilterData, setPowerSearchFilterData] =
    useState<null | PowerSearchFilterData>(null);

  const { applicationList: applicationListPageData } =
    useLoaderData<ApplicationPageLoaderData>();

  const { data: applicationList, refetch: refetchApplicationList } =
    useGetWithLoader<Array<ApiGet_PartnerApplicationType>>(
      getApplicationEndpoint(),
      applicationListPageData
    );

  function getSearchedItems(
    formattedItems: Array<{
      id: ID;
      leadPartner: string;
      loanProductName: string;
      menu: null;
      orgName: string;
      stepName: string;
    }>
  ) {
    const searchText = searchTerm.toLowerCase();
    if (searchText.length > 0) {
      return formattedItems.filter((item) =>
        item.orgName.toLowerCase().includes(searchText)
      );
    }
    return formattedItems;
  }

  function formatItemsData() {
    const formattedItems = applicationList.map((item) => {
      const { currentStep, leadPartner } = item;

      return {
        id: item.id,
        leadPartner: `${leadPartner.firstName} ${leadPartner.lastName}`,
        loanProductName: item.loanProductName,
        menu: null,
        orgName: item.borrowerOrganizationName,
        stepName: currentStep.stepName,
      };
    });

    return getSearchedItems(formattedItems);
  }

  const items = formatItemsData();
  const filteredItems = powerSearchFilterItems(powerSearchFilterData, items);

  const optionsMap = useMemo(() => {
    const out: PowerSearchOptionsMap = {
      leadPartner: {
        label: "Lead",
        options: new Set<string>(),
      },
      loanProductName: {
        label: "Loan Product",
        options: new Set<string>(),
      },
      stepName: {
        label: "Step",
        options: new Set<string>(),
      },
    };

    const keys = Object.keys(out) as Array<keyof (typeof items)[number]>;
    for (const key of keys) {
      for (const item of items) {
        if (out[key] != null) {
          out[key].options.add(String(item[key]));
        }
      }
    }

    return out;
  }, [items]);

  const handleFilter = useCallback(
    (filterData: PowerSearchFilterData | null) => {
      setPowerSearchFilterData(filterData);
    },
    []
  );

  const itemsLength = filteredItems.length;

  const contentProps: ApplicationPartnerPageProps = {
    handleFilter,
    items: filteredItems,
    itemsLength,
    optionsMap,
    powerSearchFilterData,
    refetchApplicationList,
    searchTerm,
    setSearchTerm,
  };

  return (
    <MobileResponsiveContainer
      desktop={<ApplicationPartnerPageContentDesktop {...contentProps} />}
      mobile={<ApplicationPartnerPageContentMobile {...contentProps} />}
    />
  );
}
