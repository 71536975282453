import * as React from "react";
import { EmailVerificationStatus } from "@presta-technologies-inc/presta-types";

const EmailVerificationSuccessCardContent = React.lazy(
  () => import("./EmailVerificationSuccessCardContent")
);

const EmailVerificationExpiredCodeCardContent = React.lazy(
  () => import("./EmailVerificationExpiredCodeCardContent")
);

type Props = {
  isVerified: boolean;
  status: string;
};

export default function EmailVerificationVerifyCodeContent({
  isVerified,
  status,
}: Props): JSX.Element | null {
  switch (status) {
    case EmailVerificationStatus.Verified:
      if (isVerified) {
        return <EmailVerificationSuccessCardContent />;
      }
      break;
    // change to expired once backend PR is merged
    case EmailVerificationStatus.Unverified:
    default:
      return <EmailVerificationExpiredCodeCardContent />;
  }
  throw new Error(
    "there must be a valid returned JSX for status and isVerified"
  );
}
