import { useMemo } from "react";
import useIsMobileScreenSize from "hooks/useIsMobileScreenSize";

type Props = {
  desktop?: JSX.Element;
  mobile?: JSX.Element;
};
/**
 * Render a mobile or desktop view based on the screen width.
 *
 * It would probably be best to lazily load the components passed to
 * `mobile` or `desktop` props so that only the relevant code is loaded
 */
export default function MobileResponsiveContainer({
  desktop,
  mobile,
}: Props): JSX.Element | null {
  const isMobileScreenSize = useIsMobileScreenSize();

  const memoizedMobile = useMemo(() => mobile ?? null, [mobile]);
  const memoizedDesktop = useMemo(() => desktop ?? null, [desktop]);

  if (isMobileScreenSize) {
    return memoizedMobile;
  }
  return memoizedDesktop;
}
