import { Permission, PermissionData, User } from "api/endpoints/auth/login";
import { CapitalizedUserTypes } from "types/UserTypes";

export default abstract class ViewerContextBase {
  public PERMISSIONS: {
    [permission: Permission]: PermissionData;
  } = {};

  private user: User | null = null;

  private viewerType: CapitalizedUserTypes | null = null;

  constructor(user?: User | null) {
    if (user != null) {
      this.user = user;
    }
    this.init(user ?? null);
  }

  private init(user: User | null): void {
    if (user != null) {
      this.addPermissions(user?.permissions ?? []).setUserType(user);
    }
  }

  private addPermissions(permissions: Array<PermissionData>): this {
    for (const permission of permissions) {
      const { name } = permission;
      this.PERMISSIONS[name] = permission;
    }
    return this;
  }

  private setUserType(user: User): this {
    this.viewerType = user.orgType;
    return this;
  }

  public setUser(user: User): this {
    this.user = user;
    return this;
  }

  public getIsLender() {
    return this.user?.isLender ?? false;
  }

  public getIsBorrower() {
    return this.user?.isBorrower ?? false;
  }

  public getIsPartner() {
    return this.getViewerType() === CapitalizedUserTypes.PARTNER;
  }

  public getIsAdmin() {
    return this.user?.isAdmin ?? false;
  }

  public getBorrowerId() {
    return this.user?.borrowerId ?? null;
  }

  public getLenderId() {
    return this.user?.lenderId ?? null;
  }

  public getPartnerId() {
    return this.user?.partnerId ?? null;
  }

  public getUser() {
    return this.user;
  }

  /**
   * Same concept as getUser, but will throw if
   * user is nullish
   */
  public getUserX() {
    if (this.user == null) {
      throw new Error(`User must be defined`);
    }
    return this.user;
  }

  public getViewerType(): CapitalizedUserTypes | null {
    return this.viewerType;
  }
}
