import type { User } from "api/schemas/borrower/get-team";
import type { CapitalizedUserTypes } from "types/UserTypes";

export type ApiExportApplication = {
  borrowerOrganizationName: string;
  id: ID;
};

export enum ApiFileExportStatus {
  Completed = "Completed",
  Deleted = "Deleted",
  Failed = "Failed",
  InProgress = "In Progress",
  Requested = "Requested",
}

export enum ApiExportStatus {
  Active = "Active",
  Expired = "Expired",
}

export type ApiGet_ExportRequest = {
  applicationIds: ID[];
  applications: ApiExportApplication[];
  expireAt: Date;
  expireStatusText: string;
  exportFileStatus: ApiFileExportStatus;
  exportStatus: ApiExportStatus;
  id: ID;
  requestedAt: Date;
  requestedByUser: User;
  requestedByUserCode: string;
  requestedUserOrgId: ID;
  requestedUserOrgType: CapitalizedUserTypes;
};

/**
 *
 * @returns "export/applications"
 */
export default function getExportRequestsEndpoint() {
  return "export/applications";
}
