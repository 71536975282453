export type ApiPut_AccountBody = {
  code: string;
  email?: string;
  firstName?: string;
  lastName?: string;
};

export default function getAccountEndpoint() {
  return "account";
}
