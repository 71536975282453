import * as React from "react";
import { Button, CircularProgress, ButtonProps, Box } from "@mui/material";

type Props = {
  children: React.ReactNode;
  dataTestId?: string;
  icon?: React.ReactNode;
  isLoading: boolean;
} & ButtonProps;
export default function LoadingButton(props: Props) {
  const {
    children,
    dataTestId,
    icon,
    isLoading = false,
    ...buttonProps
  } = props;
  function renderIcon() {
    let content = icon;
    if (isLoading) {
      content = <CircularProgress size={20} />;
    }
    if (content == null) {
      return null;
    }
    return (
      <Box alignItems="center" component="span" display="flex" mr={0.5}>
        {content}
      </Box>
    );
  }
  return (
    <Button
      data-testid={dataTestId ?? undefined}
      {...buttonProps}
      sx={{ alignItems: "center", display: "flex" }}
    >
      {renderIcon()}
      {children}
    </Button>
  );
}
