import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Stack,
} from "@mui/material";
import getApplicationByIdEndpoint from "api/endpoints/delete/application/getApplicationByIdEndpoint";
import { ApiGet_ApplicationType } from "api/endpoints/get/application/getApplicationEndpoint";
import useMutation from "hooks/useMutation";
import { useSnackbar } from "context/SnackbarContext";

type Props = {
  applicationId: ID;
  closeModal(): void;
  isModalOpen: boolean;
  refetchApplicationList: () => Promise<ApiGet_ApplicationType[]>;
};

export default function ApplicationDeleteApplicationModal({
  applicationId,
  closeModal,
  isModalOpen,
  refetchApplicationList,
}: Props) {
  const { openSnackbar } = useSnackbar();
  const [commitDelete] = useMutation({ method: "DELETE" });

  function handleDelete() {
    commitDelete({
      endpoint: getApplicationByIdEndpoint(applicationId),
      onCompleted() {
        refetchApplicationList();
        closeModal();
        openSnackbar("Application deleted");
      },
    });
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClick={(e) => e.preventDefault()}
      onClose={closeModal}
      open={isModalOpen}
    >
      <DialogTitle>Delete Application</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <DialogContentText>
            Are you sure you want to delete this application?
          </DialogContentText>
          <DialogContentText>
            You will no longer have access to this application from your
            dashboard. Should you want to restore it in the future, reach out to
            the Presta team.
          </DialogContentText>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          color="error"
          onClick={() => {
            handleDelete();
          }}
          variant="contained"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
