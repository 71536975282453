import { LoaderFunction, RouteObject, To, redirect } from "react-router-dom";
import ViewerContext from "context/UserContextV2/ViewerContext";
import BaseRouteController from "routes/BaseRouteController";
import getNavigateConfig from "utilities/getNavigateConfig";
import getQuerySearchParams from "utilities/getQuerySearchParams";
import client from "utilities/apiClient";
import getCurrentUserEndpoint, {
  ApiGet_UserCurrentUserResponse,
} from "api/endpoints/get/user/getCurrentUserEndpoint";
import CookieUtils from "utilities/CookieUtils";
import ApplicationsPageRootRouteController from "routes/ApplicationsPage/ApplicationsPageRootRouteController";
import isEmailVerificationGKEnabled from "utilities/gatekeeper/gkChecks/isEmailVerificationGKEnabled";
import { genEmailVerificationVerifyCodePageLoader } from "./EmailVerificationVerifyCodePage";
import EmailVerificationPage from "./EmailVerificationPage";

export default class EmailVerificationPageRouteController extends BaseRouteController {
  public static readonly QUERY_CODE_PARAM = "code";

  public getRouteConfig(): RouteObject {
    return {
      children: this.getChildren(),
      element: this.getElement(),
      errorElement: this.getErrorElement(),
      loader: this.getLoader(),
      path: this.getPath(),
    };
  }

  public override loader: LoaderFunction = async () => {
    const token = CookieUtils.getCookie<string>("token");
    if ((token?.length ?? 0) > 0) {
      const {
        data: { user },
      } = await client<ApiPayload<ApiGet_UserCurrentUserResponse>>(
        getCurrentUserEndpoint(),
        { staleTime: 60000 }
      );
      if (Boolean(user?.isEmailVerified)) {
        return redirect(ApplicationsPageRootRouteController.getRoutePath());
      }
    }
    const { code } = getQuerySearchParams<{ code: string | undefined }>();
    if (code != null) {
      return await genEmailVerificationVerifyCodePageLoader(code);
    }
    return undefined;
  };

  /**
   * /email-verification
   */
  public static override getRoutePath(): "/email-verification" {
    return "/email-verification";
  }

  /**
   * Returns the full path with the existing url query params
   */
  public static override buildRoutePath(): To {
    return getNavigateConfig(
      EmailVerificationPageRouteController.getRoutePath(),
      {
        paramsToPersist: [
          EmailVerificationPageRouteController.QUERY_CODE_PARAM,
        ],
      }
    );
  }

  public getPath(): "email-verification" {
    return "email-verification";
  }

  public async genCanUserView(): Promise<boolean> {
    const user = this.getVC().getUser();
    return user != null && isEmailVerificationGKEnabled();
  }

  public getErrorElement(): undefined {
    return undefined;
  }

  public getElementImpl(): JSX.Element | undefined {
    return <EmailVerificationPage />;
  }

  public getChildrenRouteControllers(): (new (
    viewerContext: ViewerContext
  ) => BaseRouteController)[] {
    return [];
  }
}
