import * as React from "react";
import getQuerySearchParams from "utilities/getQuerySearchParams";
import EmailVerificationPageRouteController from "./EmailVerificationPageRouteController";

const EmailVerificationResendEmailPage = React.lazy(
  () => import("./EmailVerificationResendEmailPage")
);

const EmailVerificationVerifyCodePage = React.lazy(
  () => import("./EmailVerificationVerifyCodePage")
);

export default function EmailVerificationPageContent() {
  const { code } = getQuerySearchParams<{
    [EmailVerificationPageRouteController.QUERY_CODE_PARAM]: string;
  }>();

  if (code == null) {
    return <EmailVerificationResendEmailPage />;
  }

  return <EmailVerificationVerifyCodePage />;
}
