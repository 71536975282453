import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import stylex from "utilities/stylex";
import LoginRouteController from "routes/LoginPage/LoginRouteController";

const styles = stylex({
  linkText: { pt: 1, textDecoration: "underline" },
});

export default function ResetPasswordLoginButton() {
  return (
    <Typography
      color="primary"
      component={Link}
      data-testid="back-to-log-in-link"
      sx={styles.linkText}
      to={LoginRouteController.buildRoutePath()}
    >
      Back to log in
    </Typography>
  );
}
