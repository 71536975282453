import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";
import { useState } from "react";
import { InputType } from "types/InputType";
import Input, { Props } from "./mui/Inputs/Input";

const InputImpl = styled(Input)(() => ({
  paddingRight: 0,
}));

export default function PasswordInput(props: Props): JSX.Element {
  const [isPeeking, setIsPeeking] = useState(false);

  return (
    <InputImpl
      endAdornment={
        <IconButton
          data-testid="password-input-toggle-view"
          onClick={() => setIsPeeking((oldVal) => !oldVal)}
        >
          {isPeeking ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      }
      type={isPeeking ? InputType.TEXT : InputType.PASSWORD}
      {...props}
    />
  );
}
