import { RouteObject, To } from "react-router-dom";
import ViewerContext from "context/UserContextV2/ViewerContext";
import BaseRouteController from "routes/BaseRouteController";
import getNavigateConfig from "utilities/getNavigateConfig";
import ResetPasswordSuccessPage from "routes/ResetPasswordPage/ResetPasswordSuccessPage";

export default class ResetPasswordSuccessRouteController extends BaseRouteController {
  public getRouteConfig(): RouteObject {
    return {
      children: this.getChildren(),
      element: this.getElement(),
      errorElement: this.getErrorElement(),
      loader: this.getLoader(),
      path: this.getPath(),
    };
  }

  /**
   * /reset-password/success
   */
  public static override getRoutePath(): string {
    return "/reset-password/success";
  }

  /**
   * Returns the full path with the existing url query params
   */
  public static override buildRoutePath(): To {
    return getNavigateConfig(
      ResetPasswordSuccessRouteController.getRoutePath()
    );
  }

  public getPath(): string | undefined {
    return "success";
  }

  public async genCanUserView(): Promise<boolean> {
    return true;
  }

  public getErrorElement(): JSX.Element | undefined {
    return undefined;
  }

  public getElementImpl(): JSX.Element | undefined {
    return <ResetPasswordSuccessPage />;
  }

  public getChildrenRouteControllers(): (new (
    viewerContext: ViewerContext
  ) => BaseRouteController)[] {
    return [];
  }
}
