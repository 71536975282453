export default function sanitizeFilterSet(presetFilterSet: string) {
  // Parse the JSON string
  const parsed = JSON.parse(presetFilterSet);

  // Helper to check if value is empty (empty array or empty Set)
  const isEmpty = (value: string | Array<string> | Set<string>) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    if (value instanceof Set) {
      return value.size === 0;
    }
    if (typeof value === "object" && value !== null) {
      return Object.keys(value).length === 0;
    }
    return false;
  };

  // Remove non-alphanumeric characters from keys and filter out empty values
  const sanitized = Object.entries(parsed).reduce((acc, [key, value]) => {
    // Remove non-alphanumeric characters from key
    const sanitizedKey = key.replace(/[^a-zA-Z0-9]/g, "");
    // Skip if value is empty
    if (isEmpty(value as string | string[] | Set<string>)) {
      return acc;
    }

    // Handle nested is/isNot objects
    if (
      value !== null &&
      typeof value === "object" &&
      ("is" in value || "isNot" in value)
    ) {
      const sanitizedValue = {
        is:
          "is" in value &&
          Boolean(value.is) &&
          !isEmpty(value.is as string | string[] | Set<string>)
            ? value.is
            : undefined,
        isNot:
          "isNot" in value &&
          Boolean(value.isNot) &&
          !isEmpty(value.isNot as string | string[] | Set<string>)
            ? value.isNot
            : undefined,
      };

      // Only add if either is or isNot has values
      if (Boolean(sanitizedValue.is) || Boolean(sanitizedValue.isNot)) {
        acc[sanitizedKey] = sanitizedValue;
      }
    } else {
      acc[sanitizedKey] = value;
    }

    return acc;
  }, {} as Record<string, unknown>);

  // Convert sanitized object to semicolon-delimited string
  const result = Object.entries(sanitized)
    .map(([key, value]) => {
      if (
        value !== null &&
        typeof value === "object" &&
        ("is" in value || "isNot" in value)
      ) {
        const parts = [];
        if ("is" in value && Boolean(value.is))
          parts.push(`${key}:is:${value.is}`);
        if ("isNot" in value && Boolean(value.isNot))
          parts.push(`${key}:isNot:${value.isNot}`);
        return parts.join(";");
      }
      return `${key}:${value}`;
    })
    .filter(Boolean)
    .join(";");

  return result;
}
