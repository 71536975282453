import { ReactQueryDevtools } from "react-query/devtools";
import { PrestaCookiesProvider } from "context/PrestaCookiesContext";
import FullScreenLoaderProvider from "context/FullScreenLoaderProvider";
import AppThemeProvider from "./ThemeProvider";
import { SnackbarContextProvider } from "./SnackbarContext";
import ReactQueryProvider from "./ReactQueryProvider";
import UserContextV2Provider from "./UserContextV2";
import StatsigProvider from "./StatsigProvider";

export default function AppProvider({ children }: { children: JSX.Element }) {
  return (
    <PrestaCookiesProvider>
      <UserContextV2Provider>
        <StatsigProvider>
          <AppThemeProvider>
            <SnackbarContextProvider>
              <ReactQueryProvider>
                <FullScreenLoaderProvider>
                  {children}
                  <ReactQueryDevtools />
                </FullScreenLoaderProvider>
              </ReactQueryProvider>
            </SnackbarContextProvider>
          </AppThemeProvider>
        </StatsigProvider>
      </UserContextV2Provider>
    </PrestaCookiesProvider>
  );
}
