import getHasSequentialCharacters from "./getHasSequentialCharacters";
import getHasRepeatedCharacters from "./getHasRepeatedCharacters";

const MAX_PASSWORD_LENGTH = 64;
const MIN_PASSWORD_LENGTH = 12;
const MAX_REPETITIVE_CHARACTERS = 4;

/**
 * Returns a boolean representing whether a string meets our criteria
 * for an acceptable password
 * @param password string to be checked
 */
export default function getIsValidPassword(password: string) {
  const isMinLength = password.length >= MIN_PASSWORD_LENGTH;

  const isMaxLength = password.length <= MAX_PASSWORD_LENGTH;

  // requires password has either a non-word character (!@#$%^&*()*~,.\/?-+=) or an underscore (_)
  const hasSpecialCharacter = /\W|_/g.test(password);

  const hasLetters = /[a-zA-Z]/.test(password);

  const hasNumbers = /[0-9]/.test(password);

  const isSequential = getHasSequentialCharacters(
    password,
    MAX_REPETITIVE_CHARACTERS
  );

  const isRepeated = getHasRepeatedCharacters(
    password,
    MAX_REPETITIVE_CHARACTERS
  );

  return (
    isMinLength &&
    isMaxLength &&
    hasLetters &&
    hasNumbers &&
    hasSpecialCharacter &&
    !isSequential &&
    !isRepeated
  );
}
