import { useEffect, useRef, useState } from "react";
import {
  AutocompleteRenderGetTagProps,
  Box,
  ButtonBase,
  Checkbox,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Close } from "@mui/icons-material";
import { PowerSearchFilterData } from "./PowerSearchTypes";
import {
  selectedFilterForOptionsAtomFamily,
  selectedOptionsForValueAtomFamily,
  selectedPowerSearchValuesAtom,
} from "./PowerSearchRecoil";

export default function PowerSearchTag({
  initialOptions,
  options,
  tagProps,
  value,
}: {
  initialOptions: PowerSearchFilterData[string] | null;
  options: {
    label: string;
    options: Set<string>;
  };
  tagProps: ReturnType<AutocompleteRenderGetTagProps>;
  value: string;
}) {
  const ref = useRef<HTMLElement | null>(null);

  const [is, setIs] = useRecoilState(selectedFilterForOptionsAtomFamily(value));

  const setSelectedValues = useSetRecoilState(selectedPowerSearchValuesAtom);

  const [selectedOptions, setSelectedOptions] = useRecoilState(
    selectedOptionsForValueAtomFamily(value)
  );

  const [booleanAnchorEl, setBooleanAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const [optionAnchorEl, setOptionAnchorEl] = useState<HTMLElement | null>(
    null
  );

  useEffect(() => {
    // handles setting the initial options, if there are any from the query params
    let newIs = true;
    if (initialOptions?.is == null && initialOptions?.isNot == null) {
      newIs = true;
    } else {
      newIs = initialOptions?.is?.size > 0;
    }
    setIs(newIs);

    let newOptions = newIs ? initialOptions?.is : initialOptions?.isNot;
    newOptions = newOptions ?? new Set();
    setSelectedOptions(newOptions);
  }, [initialOptions?.is, initialOptions?.isNot, setIs, setSelectedOptions]);

  useEffect(() => {
    // handles showing the options menu when selecting a new value
    if (ref.current != null && initialOptions == null) {
      setOptionAnchorEl(ref.current);
    } else {
      setOptionAnchorEl(null);
    }
  }, [initialOptions]);

  useEffect(() => {
    return () => {
      // resets the selected options if the value is ever removed
      setSelectedOptions(new Set());
      setIs(true);
    };
  }, [setIs, setSelectedOptions]);

  function handleSelectOption(option: string) {
    const newOptions = new Set(selectedOptions);
    const hasOption = newOptions.has(option);
    if (hasOption) {
      newOptions.delete(option);
    } else {
      newOptions.add(option);
    }
    setSelectedOptions(newOptions);
  }

  function removeFilter(): void {
    setSelectedValues((oldValues) => {
      return oldValues.filter((val) => val !== value);
    });
  }

  const isOptionMenuEl = (
    <Menu
      anchorEl={booleanAnchorEl}
      onClose={() => setBooleanAnchorEl(null)}
      open={booleanAnchorEl != null}
    >
      <MenuItem
        onClick={() => {
          setIs(true);
          setBooleanAnchorEl(null);
        }}
      >
        is
      </MenuItem>
      <MenuItem
        onClick={() => {
          setIs(false);
          setBooleanAnchorEl(null);
        }}
      >
        is not
      </MenuItem>
    </Menu>
  );

  const optionsMenuEl = (
    <Menu
      anchorEl={ref.current ?? optionAnchorEl}
      onClose={() => {
        ref.current = null;
        setOptionAnchorEl(null);
      }}
      open={optionAnchorEl != null}
    >
      {Array.from(options.options).map((option, idx) => {
        return (
          <MenuItem
            key={`${value}-${option}-${idx}`}
            onClick={() => {
              handleSelectOption(option);
            }}
          >
            <Checkbox checked={selectedOptions.has(option)} />
            {option}
          </MenuItem>
        );
      })}
    </Menu>
  );

  const { onDelete: _onDelete, ...props } = tagProps;

  return (
    <Box {...props}>
      <Stack
        alignItems="center"
        direction="row"
        sx={(theme) => ({
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: "2px",
          color: theme.palette.common.black,
        })}
      >
        <Typography px={1}>{options.label}</Typography>

        <Divider flexItem orientation="vertical" />

        <ButtonBase
          component={Typography}
          onClick={(e) => setBooleanAnchorEl(e.currentTarget)}
          sx={{ px: 1 }}
        >
          {is ? "is" : "is not"}
        </ButtonBase>

        <Divider flexItem orientation="vertical" />

        <ButtonBase
          ref={ref}
          component={Typography}
          onClick={(e) => setOptionAnchorEl(e.currentTarget)}
          sx={{ minHeight: "30px", minWidth: "20px", px: 1 }}
        >
          {Array.from(selectedOptions).join(", ")}
        </ButtonBase>

        <IconButton
          aria-label="remove filter"
          onClick={removeFilter}
          sx={{ borderRadius: "unset" }}
        >
          <Close fontSize="small" />
        </IconButton>
      </Stack>

      {isOptionMenuEl}

      {optionsMenuEl}
    </Box>
  );
}
