import { useState } from "react";
import { Link } from "react-router-dom";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import stylex from "utilities/stylex";
import ApplicationsExportPageRouteController from "routes/ApplicationsPage/ApplicationsExportPage/ApplicationsExportPageRouteController";

const styles = stylex({
  menu: {
    border: (theme) => `1px solid ${theme.palette.primary.main}`,
    borderRadius: "4px",
  },
});

export default function ApplicationsExportButtonMenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton onClick={onClick} sx={styles.menu}>
        <MoreVert color="primary" />
      </IconButton>
      <Menu anchorEl={anchorEl} onClose={closeMenu} open={Boolean(anchorEl)}>
        <MenuItem
          component={Link}
          to={ApplicationsExportPageRouteController.buildRoutePath()}
        >
          Export Applications
        </MenuItem>
      </Menu>
    </>
  );
}
