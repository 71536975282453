import { Permission } from "api/endpoints/auth/login";
import ViewerContextBase from "./ViewerContextBase";

export default class ViewerContext extends ViewerContextBase {
  public canViewer(permission: Permission): boolean {
    return this.PERMISSIONS[permission].isEnabled;
  }

  public async genCanViewer(permission: Permission): Promise<boolean> {
    // call api for permission
    const resp = this.PERMISSIONS;
    return resp[permission].isEnabled;
  }
}
